import { useEffect, useState } from "react";
import { Modal, ModalContentSection } from "../../components/Modal";
import { PlayerCharacter } from "../../model/character";
import { SpeciesItems } from "../../components/SpeciesSelector";
import { newCharacter } from "../../master/data";
import { TextField } from "../../components/formFields/TextField";
import { NumberField } from "../../components/formFields/NumberField";
import FormFieldLabel from "../../components/formFields/FormFieldLabel";
import { Grid, MenuItem, Select, Stack, Typography } from "@mui/material";
import { SpeciesList } from "../../model/species";

import RulesetFA2 from '../../images/rulesets/Rules_FA2.jpg'
import RulesetFE2 from '../../images/rulesets/Rules_FE2.jpg'
import RulesetFK2 from '../../images/rulesets/Rules_FK2.jpg'

interface NewCharacterProps {
    open: boolean;
    onCreateCharacter: (character: PlayerCharacter) => void;
    onClose: () => void;
}

type Ruleset = {
    id: string;
    image: string;
    name: string;
    enabled: boolean;
}

const rulesetList: Ruleset[] = [
    { id: "fraggedEmpire", image: RulesetFE2, name: "Fragged Empire 2", enabled: true},
    { id: "fraggedKingdom", image: RulesetFK2, name: "Fragged Kingdom 2", enabled: false},
    { id: "fraggedAeternum", image: RulesetFA2, name: "Fragged Aeternum 2", enabled: false},
];

const RulesetItem = (props: { ruleset: Ruleset, onClick: () => void, selected: boolean} ) => {
    return <Grid item xs={4} md={3} sx={{opacity: props.selected ? 1 : 0.7}}>
        <img onClick={() => props.ruleset.enabled ? props.onClick() : undefined} style={{ aspectRatio: "1:1", width:"100%", cursor:"pointer", display: "block"}} src={props.ruleset.image} />
    </Grid>
}

const NewCharacter = (props: NewCharacterProps) => {
    const {open, onCreateCharacter, onClose} = props;
    const [character, setCharacter] = useState<PlayerCharacter>(newCharacter);
    const [rulset, setRuleset] = useState<Ruleset>(rulesetList[0]);

    useEffect(() => {
        if(open) {
            setCharacter(newCharacter())
            setRuleset(rulesetList[0]);
        }
    }, [open])

    const SetValue = (key: string, value: any) => {
        setCharacter((current) => {
            return {
                ...current,
                [key]: value
            };
        })
    }

    const SetInventoryValue = (key: string, value: any) => {
        setCharacter((current) => {
            return {
                ...current,
                inventory: {
                    ...current.inventory,
                    [key]: value
                }
            };
        })
    }

    const handleLevelChange = (value: number) => {
        SetValue("competence", (value * character.competencePerLevel) - character.competencePerLevel);
    }

    return <Modal
        title="New Character"
        onClose={onClose} open={open} actions={[
        {   primary: true, label: "Create", onClick: () => {
            onCreateCharacter(character);
            onClose();
        }},
        {
            label: "Close", onClick: onClose
        }
    ]}>
        <TextField placeholder="Character Name" value={character.name} onChange={(value) => SetValue("name", value)} />
        <ModalContentSection title={`Ruleset: ${rulset.name}`} collapsable>
            <Grid container spacing={1}>
                { rulesetList.map((r) => {
                    return <RulesetItem ruleset={r} onClick={() => setRuleset(r)} selected={r.id === rulset.id} key={r.id} />
                })}
            </Grid>
        </ModalContentSection>
        <ModalContentSection title={`Species: ${character.species.name}`} collapsable>
            <SpeciesItems species={character.species.id} onSpeciesChange={(value) => {
                    SetValue("species", value);
                    SetValue("subculture", SpeciesList[value.id]?.subcultures?.[0] ?? "");
            }} />
        </ModalContentSection>
        <ModalContentSection title={`Subculture: ${character.subculture}`} collapsable>
            <Select
                value={character.subculture}
                onChange={(e) => SetValue("subculture", e.target.value)}>
                {SpeciesList[character.species.id]?.subcultures.map((sc) => <MenuItem value={sc}>{sc}</MenuItem>)}
            </Select>
        </ModalContentSection>
        <ModalContentSection title="Starting Values" collapsable>
            <Stack spacing={1}>
                <FormFieldLabel label="Level (1)" justifyContent="end">
                    <NumberField value={(character.competence + character.competencePerLevel) / character.competencePerLevel} onChange={handleLevelChange} min={1} step={1} />
                </FormFieldLabel>
                <FormFieldLabel label="Resources (Level +2)" justifyContent="end">
                    <NumberField value={character.inventory.currentResources} onChange={(value) => SetInventoryValue("currentResources", value)} min={1} step={1} />
                </FormFieldLabel>
                <FormFieldLabel label="Influence (Level +2)" justifyContent="end">
                    <NumberField value={character.inventory.currentInfluence} onChange={(value) => SetInventoryValue("currentInfluence", value)} min={1} step={1} />
                </FormFieldLabel>
                <FormFieldLabel label="Spare Time Points (Level +4)" justifyContent="end">
                    <NumberField value={character.inventory.spareTime} onChange={(value) => SetInventoryValue("spareTime", value)} min={1} step={1} />
                </FormFieldLabel>
                <FormFieldLabel label="Knowledge (3)" justifyContent="end">
                    <NumberField value={character.inventory.currentKnowledge} onChange={(value) => SetInventoryValue("currentKnowledge", value)} min={1} step={1} />
                </FormFieldLabel>
            </Stack>
        </ModalContentSection>
    </Modal>
}

export default NewCharacter;
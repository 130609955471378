import { Box, Typography, useTheme, Stack, Button } from "@mui/material";
import { ReactNode } from "react";

import { ReactComponent as HeaderEnd }  from '../images/Header_End.svg';

export interface SectionHeaderAction {
    action: () => void,
    label: ReactNode
}

interface SectionHeaderProps {
    children?: ReactNode
    actions?: SectionHeaderAction[];
}

const SectionHeader = (props: SectionHeaderProps) => {
    const { children, actions } = props;
    const theme = useTheme();

    return <Box height="35px" display="flex" marginBottom={1}>
        <Box height="35px" flexGrow={1} sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText}} display={"flex"} alignItems={"center"} paddingLeft={1}>
            <Typography margin={0} fontWeight={700} >{children}</Typography>
        </Box>
        <HeaderEnd fill={theme.palette.primary.main} height="35px" />
        { actions !== undefined && actions.length !== 0 && <Stack marginLeft={1} direction={"row"} spacing={1}>
            {actions.map((action,index) => {
                return <Button style={{ borderRadius: 0, boxShadow: "none", padding: 0, minWidth: "unset", aspectRatio: "1/1"}} variant="contained" key={index} onClick={() => action.action()}>{action.label}</Button>
            })}
            </Stack>}
    </Box>
}

export default SectionHeader;
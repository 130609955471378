import { Box, Stack, Typography, styled } from "@mui/material"
import { useNavigate } from "react-router-dom";
import { CharacterItem, deleteCharacter, exportCharacter, listCharacters, loadCharacter, saveCharacter } from "../../master/data";
import { useEffect, useState } from "react";

import { ReactComponent as DeleteIcon } from "../../components/icons/raw/Icon_Delete.svg";
import { ReactComponent as ExportIcon } from "../../components/icons/raw/Icon_Export.svg";
import Page from "../../components/Page";
import ConfirmDialog from "../../components/ConfirmDialog";
import NewCharacter from "./NewCharacterModal";
import { PlayerCharacter } from "../../model/character";
import ButtonFlat from "../../components/ButtonFlat";
import { IInstallPromptEvent } from "../../master/installEvent";

const AvatarImage = styled('img')`
    height: 65px;
    width: 65px;
`;

const CharacterName = styled('div')(({theme}) => `
    font-weight: 700;
    font-size: ${theme.fontSize.heading};
    margin: 0;
    padding: 0;
`);

const ButtonStack = styled('div')`
    width: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    & > *:not(:first-child) {
        margin-top: 5px;
    }
`;

const CharacterBox = styled('div')(({theme}) => `
    background-color: transparent;
    cursor: pointer;
    ${ButtonStack} {
        width: 40px;
    }
    &:hover {
        background-color: ${theme.palette.primary.light};
        ${DeleteIcon}, ${ExportIcon} {
            fill: ${theme.palette.grey[100]};
        }
    }
    padding: ${theme.spacing(1)};
`);



interface CharacterProps {
    character: CharacterItem;
    onSelect: () => void;
    onExport: (key: string) => void;
    onDelete: (key: string) => void;
}

const Character = (props: CharacterProps) => {
    const [ deleteConfirm, setDeleteConfirm] = useState(false);
    const { character } = props;

    const handleYes = () => {
        setDeleteConfirm(false);
        props.onDelete(character.key);
    }

    return <>
    <ConfirmDialog message={`Are you sure you want to delete ${character.name} `} onYes={handleYes} show={deleteConfirm} onNo={() => setDeleteConfirm(false)} />
    <CharacterBox><Stack direction="row" spacing={1}>
        <Box flexGrow={1} textAlign="left" onClick={() => props.onSelect()}>
            <CharacterName>{character.name}</CharacterName>
            Lvl {character.level.toString()}, {character.species.name} {character.subculture}, Fragged Empire 2
        </Box>
        <ButtonStack>
            <ExportIcon onClick={() => props.onExport(character.key)}  />
            <DeleteIcon onClick={() => setDeleteConfirm(true)} />
        </ButtonStack>
        <AvatarImage src={character.image}  onClick={() => props.onSelect()} />
    </Stack></CharacterBox></>
}

const HomePage = () => {
    const navigate = useNavigate();
    const [showCharacterCreation, setShowCharacterCreation] = useState(false);
    const [characters, setCharacters] = useState(listCharacters());
    const [ canInstall, setCanInstall ] = useState<IInstallPromptEvent | undefined>(undefined);

    useEffect(() => {
        const ready = (e: IInstallPromptEvent) => {
          e.preventDefault();
          setCanInstall(e);
        };
    
        window.addEventListener("beforeinstallprompt", ready as any);
    
        return () => {
          window.removeEventListener("beforeinstallprompt", ready as any);
        };
      }, []);

    const handleNewCharacter = (character: PlayerCharacter) => {
        saveCharacter(character);
        navigate(`/character/${character.id}`)
    }

    const handleImportCharacter = () => {
        navigate(`/character/import`)
    }

    const handleDeleteCharacter = (key: string) => {
        deleteCharacter(key);
        setCharacters(characters => {
            return characters.filter(c => c.key !== key);
        })
    }

    const handleExportCharacter = (key: string) => {
        const character = loadCharacter(key);
        exportCharacter(character);
    }

    return <Page>
        <NewCharacter open={showCharacterCreation} onClose={() => setShowCharacterCreation(false)} onCreateCharacter={(character) => handleNewCharacter(character)} />
        <Box textAlign="center">
            <Typography variant="h3" textAlign="center" marginBottom={1} fontWeight={700}>Fragged Nexus</Typography>
            <Typography textAlign="center" marginBottom={2}>A companion app for Fragged games.</Typography>

            <Stack direction="column">
                {characters.map((character) => {
                    return <Character character={character} onSelect={() => navigate(`character/${character.key}`)} onDelete={handleDeleteCharacter} onExport={handleExportCharacter} />
                })}
            </Stack>
            <Stack direction="row" spacing={2} marginTop={2} justifyContent="center">
                <ButtonFlat variant="contained" onClick={() => setShowCharacterCreation(true)}>New Character</ButtonFlat>
                <ButtonFlat variant="contained" onClick={handleImportCharacter}>Import Character</ButtonFlat>
                {canInstall !== undefined && <Box sx={{ textAlign: "center"}}>
                    <ButtonFlat onClick={() => canInstall?.prompt()}>Install App</ButtonFlat>
                </Box>}
            </Stack>
        </Box>
    </Page>
}

export default HomePage;
import { ClickAwayListener, Grid, Tooltip, Typography, useTheme } from "@mui/material";
import { PlayerCharacterDisplay } from "../../model/character";
import SimpleTable from "../../components/SimpleTable";
import { ReactNode, useState } from "react";
import HeadingBar from "../../components/HeadingBar";
import { StatDisplay } from "../../components/StatDisplay";

import { ReactComponent as ArmourIcon } from "../../components/icons/raw/Icon_Armour.svg";
import { ReactComponent as Armour0Icon } from "../../components/icons/raw/Icon_0End_Armour.svg";
import { ReactComponent as EnduranceIcon } from "../../components/icons/raw/Icon_Endurance.svg";
import { ReactComponent as ResistanceIcon } from "../../components/icons/raw/Icon_Resistance.svg";

interface OutfitDisplayProps {
    characterDisplay: PlayerCharacterDisplay;
    setSelected: (value: number) => void;
}

const Header = (props: { children?: ReactNode, tooltip?: string}) => {
    const [ open, setOpen ] = useState(false);

    return <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Tooltip title={props.tooltip} open={open} onClick={() => setOpen(true)} >
            <div>{props.children}</div>
        </Tooltip>
    </ClickAwayListener>
}

const OutfitDisplay = (props: OutfitDisplayProps) => {
    const theme = useTheme();
    const { characterDisplay, setSelected } = props;

    return <>
        <HeadingBar extendBunts>
            <Grid container paddingX={1}>
                <Grid item xs={8}><Header>Name</Header></Grid>
                <Grid item xs={1}><Header tooltip="Armor"><ArmourIcon fill={theme.palette.primary.contrastText} height="1em" /></Header></Grid>
                <Grid item xs={1}><Header tooltip="Armor at zero Endurance"><Armour0Icon fill={theme.palette.primary.contrastText} height="1em" /></Header></Grid>
                <Grid item xs={1}><Header tooltip="Endurance"><EnduranceIcon fill={theme.palette.primary.contrastText} height="1em" /></Header></Grid>
                <Grid item xs={1}><Header tooltip="Defence"><ResistanceIcon fill={theme.palette.primary.contrastText} height="1em" /></Header></Grid>
            </Grid>
        </HeadingBar>
        <SimpleTable entries={characterDisplay.outfits.map((w, index) => {
            return <Grid container onClick={() => setSelected(index)}>
                <Grid item xs={8}><Typography fontWeight={700} fontSize={15}>{w.name}</Typography></Grid>
                <Grid item xs={1}>{StatDisplay(w.armor)}</Grid>
                <Grid item xs={1}>{StatDisplay(w.armorAtZero)}</Grid>
                <Grid item xs={1}>{StatDisplay(w.endurance)}</Grid>
                <Grid item xs={1}>{StatDisplay(w.defence)}</Grid>
                <Grid item xs={12}><Typography variant="caption">{w.features.join(", ")}</Typography></Grid>
            </Grid>
        })} />
    </>
}

export default OutfitDisplay
import { Grid, Typography } from "@mui/material";
import { ReactNode } from "react";

export interface BarProps {
    label: string;
    children?: ReactNode;
}

export const Bar = (props: BarProps) => {
    return <Grid container sx={{padding:2}}>
        <Grid item xs={12} textAlign={"center"}>
            <Typography>{props.label}</Typography>
        </Grid>
        <Grid item xs={12} textAlign={"center"}>
            {props.children}
        </Grid>
    </Grid>;
}

export default Bar;
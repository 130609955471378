import { Box, Grid, useTheme } from "@mui/material"
import { PlayerCharacter, PlayerCharacterDisplay } from "../../model/character";
import styled from 'styled-components';
import { CharacterContext } from "../../model/characterContext";
import { ReactNode, useContext } from "react";
import { CombatOrder, EnduranceMaxStat, GritReroll } from "../../model/statsConstant";
import ButtonFlat from "../../components/ButtonFlat";

import { ReactComponent as CombatOrderIcon } from "../../components/icons/raw/Icon_Combat_order.svg";
import { ReactComponent as EnduranceDamageIcon } from "../../components/icons/raw/Icon_End_Damage.svg";

const OuterBox =  styled.div`
    padding: 4px;
    background-color: #23232d;
    flex-grow: 1;
    height: 100%;
`;
    
const InnerBox =  styled.div`
    position: relative;
    height:100%;
`;
    
const Label =  styled.div`
    text-align: center;
    color: #fff;
    z-index: 1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height:100%;
    font-size:
    line-height: 1em;
`;
    
const EnduranceBar = styled.div<{ amount: number }>`
    top: 0;
    left: 0;
    bottom: 0;
    position: absolute;
    width: ${props => props.amount}%;
    background-color: #dd0000;
`;

interface EnduranceDamageViewProps {
    character: PlayerCharacter;
    characterDisplay: PlayerCharacterDisplay;
    setDamage: (value: number) => void;
}

const EnduranceDamageView = (props: EnduranceDamageViewProps) => {
    const characterContext = useContext(CharacterContext)!;
    const theme = useTheme();
    const { character, characterDisplay, setDamage } = props;

    const strength = characterDisplay.attributes.find(a => a.id == "str")?.withDamage ?? 0;

    const enduranceMax = 10 + (strength ?? 0) * 5 + characterContext.getAdjustment(EnduranceMaxStat.id);
    const endurance = enduranceMax - (character.enduranceDamageTaken ?? 0);

    const grit = characterDisplay.attributes.find(a => a.id == "grit")?.withDamage ?? 0;
    const gritReroll = grit + characterContext.getAdjustment(GritReroll.id)

    const combatOrder = (characterDisplay.attributes.find(a => a.id == "int")?.withDamage ?? 0) + characterContext.getAdjustment(CombatOrder.id);

    const equipment = characterDisplay.equipmentSlotsCurrent;
    const equipmentMax = characterDisplay.equipmentSlots;

    const adjustDamage = (amount: number) => {
        setDamage(Math.min(Math.max(character.enduranceDamageTaken + amount, 0), enduranceMax));
    }

    const LabelBox = (props: {children?: ReactNode}) => {
        return <Box height="26px" style={{ 
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            display: "flex", alignItems: "center", justifyContent: "center", fontSize: 12, lineHeight: "1em"}}>{props.children}</Box>
    }
    
    return <>
        <Box marginBottom={1}>
            <Box height="35px" display="flex" marginBottom="2px">
                <ButtonFlat  onClick={() => adjustDamage(1)}>-</ButtonFlat>
                <OuterBox>
                    <InnerBox>
                        <EnduranceBar amount={endurance/enduranceMax*100} />
                        <Label>{endurance}/{enduranceMax}</Label>
                    </InnerBox>
                </OuterBox>
                <ButtonFlat onClick={() => adjustDamage(-1)}>+</ButtonFlat>
            </Box>
            <LabelBox><EnduranceDamageIcon fill={theme.palette.primary.contrastText} height="1.2em" /><Box marginLeft="4px">Endurance</Box></LabelBox>
        </Box>
        <Grid container textAlign="center" fontSize={14} spacing={1}>
            <Grid item xs={4}>{equipment}/{equipmentMax}</Grid>
            <Grid item xs={4}>0/{gritReroll}</Grid>
            <Grid item xs={4}>{combatOrder}</Grid>
        </Grid>
        <Grid container textAlign="center"spacing={1}>
            <Grid item xs={4}><LabelBox>Equipment Slots</LabelBox></Grid>
            <Grid item xs={4}><LabelBox>Grit Re-Rolls</LabelBox></Grid>
            <Grid item xs={4}><LabelBox><CombatOrderIcon fill={theme.palette.primary.contrastText} height="1.2em" /><Box marginLeft="4px">Combat Order</Box></LabelBox></Grid>
        </Grid>
    </>
}

export default EnduranceDamageView;
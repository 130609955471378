import { Box, Tab, Tabs } from "@mui/material";
import { ReactNode, useState } from "react";

export interface TabBarProps {
    pages: {
        label: ReactNode;
        content: ReactNode;
    }[]
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}
  
function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box>
            {children}
          </Box>
        )}
      </div>
    );
  }

export const TabBar = (props: TabBarProps) => {
    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return <Box>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={value} onChange={handleChange} aria-label="tabs" centered>
                {props.pages.map((p, index) => {
                    return <Tab label={p.label} {...a11yProps(index)} />;
                })}
            </Tabs>
        </Box>
        {props.pages.map((p, index) => {
            return <TabPanel value={value} index={index}>{p.content}</TabPanel>
        })}
    </Box>
}
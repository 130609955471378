import { ClickAwayListener, Collapse } from "@mui/material";
import { ReactNode, useState } from "react";

const CollapseClickAway = (props: {children: ReactNode, collapseContent?: ReactNode}) => {
    const [focused, setFocused] = useState(false);
    if(props.collapseContent === undefined) return <>{props.children}</>;
    return <ClickAwayListener onClickAway={() => setFocused(false)}>
        <div onClick={() => setFocused(true)}>
            {props.children}
            <Collapse in={focused}>
                {props.collapseContent}
            </Collapse>
        </div>
    </ClickAwayListener>
}

export default CollapseClickAway
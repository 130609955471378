import { useEffect, useState } from "react";
import { Box, Button, Container, Grid, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { TabBar } from "../components/TabBar"
import { Attribute, MaxedValue, PlayerCharacter, PlayerCharacterDisplay, Skill, Ability, Trait } from "../model/character";
import { deleteCharacter, exportCharacter, loadCharacter, saveCharacter } from "../master/data";
import { SkillField } from "../components/SkillField";
import Bar from "../components/Bar";

import { AttributeField } from "../components/AttributeField";
import TraitDisplay from "./characterPage/TraitDisplay";
import SubStatsDisplay from "./characterPage/SubStatsDisplay";
import { useNavigate, useParams } from "react-router-dom";
import ConfirmDialog from "../components/ConfirmDialog";
import { NumberField } from "../components/formFields/NumberField";
import Section from "../components/Section";
import WeaponDisplay from "./characterPage/WeaponDisplay";
import DamageDisplay from "./characterPage/DamageDisplay";
import AttributeDisplay from "../components/AttributeDisplay";
import OutfitDisplay from "./characterPage/OutfitDisplay";
import { CharacterContext } from "../model/characterContext";
import AbilityDisplay from "./characterPage/AbilityDisplay";
import ErrorBoundary from "../components/ErrorBoundary";
import EquipmentList from "./characterPage/equipment/EquipmentList";
import { EnduranceDamage, HitBonus, InfluenceMax, ResourcesMax } from "../model/statsConstant";
import { ChangeTheme } from "../master/theme";
import SpeciesEditor from "../components/SpeciesSelector";
import EnduranceDamageView from "./characterPage/SubStatsView";
import Page from "../components/Page";

import { ReactComponent as CharacterIcon } from "../components/icons/raw/Icon_Character.svg";
import { ReactComponent as MetaIcon } from "../components/icons/raw/Icon_Meta.svg";
import { ReactComponent as CritDamageIcon } from "../components/icons/raw/Icon_Crit_Damage.svg";
import { ReactComponent as RecordIcon } from "../components/icons/raw/Icon_Record.svg";
import { SpeciesList } from "../model/species";

const displayBuilder = (character: PlayerCharacter, getAdjustment: (id: string) => number): PlayerCharacterDisplay => {
    const level = 1 + Math.floor(character.competence/character.competencePerLevel);

    const attributes = character.attributes.map((c) => {  
        let currentBonus = 0;
        let maxBonus = 0;
        let damage = 0;
        currentBonus += getAdjustment(c.id);
        maxBonus += getAdjustment(`${c.id}_max`);
        for(var dam of character.damages) {
            if(dam.targetId === c.id) {
                damage -= dam.adjustment;
            }
        }
        const max = c.max + maxBonus;
        const current = Math.min(c.alloted, max) + currentBonus;
        return {...c, max, current, withDamage: damage + current, damage };
    });

    const strongHits: Ability[] = [];

    let traitHitBonus = getAdjustment(HitBonus.id);
    let traitEndruanceDamage = getAdjustment(EnduranceDamage.id);

    character.species.strongHits.forEach((ability) => strongHits.push(ability));

    const weapons = character.weapons.map((weapon) => {
        let critDamage = weapon.base.critDamage;
        let enduranceDamage = weapon.base.enduranceDamage + traitEndruanceDamage;
        let hitDice = weapon.base.hitDice;
        let hitBonus = weapon.base.hitBonus + traitHitBonus;
        let range = weapon.base.range;
        let influence = weapon.base.influence;
        let knowledge = weapon.base.knowledge;
        let resources = weapon.base.resources;
        let slots = weapon.base.slots;
        let draw = weapon.base.draw;
        let reload = weapon.base.reload;
        let strongHitRange = weapon.base.strongHitRange;
        const acquireStrings = [ weapon.base.acquire, ...weapon.variations.map((v) => v.acquire), ... weapon.modifications.map((m) => m.acquire)];
        const features = [ ...weapon.base.addFeatures ];
        strongHits.push(...weapon.base.strongHits);
        
        let skillBonus = 0 ;
        
        if(weapon.base.skillBonusId !== "")
        {
            const skill = character.combatSkills.find(a => a.id == weapon.base.skillBonusId);

            if(skill !== undefined){
                const bonusAttribute = attributes.find(a => a.id === skill.attributeId);
                const bonusAdustmentCalculator = (value: number) => {
                    if(value > 3) return 1;
                    if ( value < 2) return -1;
                    return 0;
                }
                const bonusAttributeAdjustment =  bonusAdustmentCalculator(bonusAttribute?.current ?? 2);
                const toolboxBonus = skill.toolbox ? 1 : 0
                let traitBonus = getAdjustment(skill.id);
                skillBonus = (skill.trained ? 1: -2) + toolboxBonus + (skill.workshop ? 1 : 0) + bonusAttributeAdjustment + traitBonus;

            }
        } 

        for(const block of weapon.variations) {
            critDamage += block.critDamage;
            enduranceDamage += block.enduranceDamage;
            hitDice += block.hitDice;
            hitBonus += block.hitBonus;
            range += block.range;
            influence += block.influence;
            knowledge += block.knowledge;
            resources += block.resources;
            slots += block.slots;
            draw += block.draw;
            reload += block.reload;
            strongHitRange += block.strongHitRange;
            features.push( ...block.addFeatures);
            strongHits.push(...block.strongHits);
        }

        for(const block of weapon.modifications) {
            critDamage += block.critDamage;
            enduranceDamage += block.enduranceDamage;
            hitDice += block.hitDice;
            hitBonus += block.hitBonus;
            range += block.range;
            influence += block.influence;
            knowledge += block.knowledge;
            resources += block.resources;
            slots += block.slots;
            draw += block.draw;
            reload += block.reload;
            strongHitRange += block.strongHitRange;
            features.push(...block.addFeatures);
            strongHits.push(...block.strongHits);
        }

        return {
            id: weapon.id,
            name: weapon.name,
            description: weapon.description,
            isAcquired: weapon.isAcquired,
            isHeld: weapon.isHeld,
            isStashed: weapon.isStashed,

            acquire: acquireStrings.join(", "),
            critDamage,
            enduranceDamage,
            hitDice,
            hitBonus,
            skillBonus,
            range,
            influence,
            resources,
            knowledge,
            slots,
            draw,
            features,
            reload,
            strongHitRange,
            weaponType: weapon.base.weaponType,
        }
    })

    const outfits = character.outfits.map((outfit) => {
        let armor = outfit.base.armor;
        let armorAtZero = outfit.base.armorAtZero;
        let endurance = outfit.base.endurance;
        let defence = outfit.base.defence;
        let slots = outfit.base.slots;
        let influence = outfit.base.influence;
        let knowledge = outfit.base.knowledge;
        let resources = outfit.base.resources;
        const acquireStrings = [ outfit.base.acquire, ...outfit.variations.map((v) => v.acquire), ... outfit.modifications.map((m) => m.acquire)];
        const features = [ ...outfit.base.addFeatures ];

        for(const block of outfit.variations) {
            armor += block.armor;
            armorAtZero += block.armorAtZero;
            endurance += block.endurance;
            defence += block.defence;
            slots += block.slots;
            influence += block.influence;
            knowledge += block.knowledge;
            resources += block.resources;
            features.push(...block.addFeatures);
        }

        for(const block of outfit.modifications) {
            armor += block.armor;
            armorAtZero += block.armorAtZero;
            endurance += block.endurance;
            defence += block.defence;
            slots += block.slots;
            influence += block.influence;
            knowledge += block.knowledge;
            resources += block.resources;
            features.push(...block.addFeatures);
        }

        return {
            id: outfit.id,
            name: outfit.name,
            description: outfit.description,
            isAcquired: outfit.isAcquired,
            isWorn: outfit.isWorn,
            isStashed: outfit.isStashed,

            acquire: acquireStrings.join(", "),
            armor,
            armorAtZero,
            defence,
            endurance,
            slots,
            influence,
            knowledge,
            resources,
            outfitType: outfit.base.outfitType,
            features,
        }
    })

    for(const utility of character.utilities) {
        strongHits.push(...utility.strongHits);
    }

    for(const equipment of character.equipment) {
        strongHits.push(...equipment.strongHits);
    }

    for(const trait of character.traits) {
        strongHits.push(...trait.strongHits);
    }

    for(const trait of character.perks) {
        strongHits.push(...trait.strongHits);
    }

    for(const trait of character.complications) {
        strongHits.push(...trait.strongHits);
    }

    let equipmentCurrent = 0
    for(const weapon of weapons) {
        if(weapon.isAcquired && !weapon.isHeld && !weapon.isStashed)
            equipmentCurrent += weapon.slots;
    }
    for(const outfit of outfits) {
        if(outfit.isAcquired && !outfit.isWorn && !outfit.isStashed)
            equipmentCurrent += outfit.slots;
    }
    for(const equipment of character.equipment) {
        if(equipment.isAcquired && !equipment.isStashed) 
            equipmentCurrent += equipment.slots;
    }
    for(const utility of character.utilities) {
        if(utility.isAcquired && !utility.isStashed) 
            equipmentCurrent += utility.slots;
    }
    const equipmentMax = 6 + (character.attributes.find(a => a.id == "str")?.alloted ?? 0);

    return {
        name: character.name,
        competence: character.competence,
        competencePerLevel: character.competencePerLevel,
        level: level,
        inventory: { ...character.inventory },
        attributes,
        combatSkills: [...character.combatSkills],
        primarySkills: [...character.primarySkills],
        spaceSkills: [...character.spaceSkills],
        traits: [...character.traits],
        complications: character.complications,
        complicationsMin: 1 + Math.floor(level/5),
        perks: character.perks,
        perksMin: Math.floor(character.inventory.currentInfluence/5),
        equipmentSlots: equipmentMax,
        equipmentSlotsCurrent: equipmentCurrent,
        equipment: character.equipment,
        outfits,
        utility: character.utilities,
        weapons,
        weaponSlots: 3,
        tradeGoods: [],
        selectedWeapon: "",
        damages: character.damages,
        strongHits,
    }
}

const makeAdjustments = (character: PlayerCharacter) => {
    const adjustments: {[k: string]: number} = {};

    const addAdjustment = (id: string, value: number) => {
        if(adjustments[id] === undefined){
            adjustments[id] = value;
        } else {
            adjustments[id] += value;
        }
    }

    for(var trait of character.traits) {
        for(var adjustment of trait.adjustments){
            addAdjustment(adjustment.targetId, adjustment.adjustment);
        }
    }

    for(var trait of character.perks) {
        for(var adjustment of trait.adjustments){
            addAdjustment(adjustment.targetId, adjustment.adjustment);
        }
    }

    for(var trait of character.complications) {
        for(var adjustment of trait.adjustments){
            addAdjustment(adjustment.targetId, adjustment.adjustment);
        }
    }

    for(var adjustment of character.species.adjustments){
        addAdjustment(adjustment.targetId, adjustment.adjustment);
    }

    return adjustments;
}

const DeleteCharacterButton = (props: { character: PlayerCharacter}) => {
    const navigate = useNavigate();
    const [ showConfirm, setShowConfirm ] = useState(false);
    
    const handleYes = () => {
        setShowConfirm(false);
        deleteCharacter(props.character.id);
        navigate("/");
    }
    
    const handleNo = () => {
        setShowConfirm(false)
    }
    
    return <>
        <ConfirmDialog show={showConfirm} message={`Are you sure you want to delete ${props.character.name}`} onNo={handleNo} onYes={handleYes} />
        <Button onClick={() => setShowConfirm(true)}>Delete</Button>
    </>
}




export const CharacterPage = () => {
    const { characterKey } = useParams();
    const [ character, setCharacter ] = useState<PlayerCharacter>(loadCharacter(characterKey ?? ""));
    const [ adjustments, setAdjustments] = useState(makeAdjustments(character));
    const GetAdjustment = (id: string) => {
        return adjustments[id] ?? 0;
    }

    const [ display, setDisplay ] = useState<PlayerCharacterDisplay>(displayBuilder(character, GetAdjustment));

    useEffect(() => {
        saveCharacter(character);
        setAdjustments(makeAdjustments(character));
        setDisplay(displayBuilder(character, GetAdjustment));

        const color = character.species.color !== "" ? character.species.color : "#3c61b9";
        ChangeTheme( color, color);
    }, [character]);

    if(characterKey === undefined || character === undefined) {
        return <>No Character Key</>
    }

    const SetNotes = (value: string) => {
        setCharacter({
            ...character,
            notes: value
        })
    }

    const SetCombatSkill = (value: Skill) => {
        setCharacter({
            ...character,
            combatSkills: character.combatSkills.map((s) => {
                return s.id === value.id ? value : s;
            })
        })
    }

    const SetSpaceSkill = (value: Skill) => {
        setCharacter({
            ...character,
            spaceSkills: character.spaceSkills.map((s) => {
                return s.id === value.id ? value : s;
            })
        })
    }

    const SetSkill = (value: Skill) => {
        setCharacter({
            ...character,
            primarySkills: character.primarySkills.map((s) => {
                return s.id === value.id ? value : s;
            })
        })
    }

    const SetAttribute = (value: Attribute) => {
        setCharacter({
            ...character,
            attributes: character.attributes.map((a) => {
                return a.id === value.id ? value : a;
            })
        })
    }

    const SetValue = (name: string, value: any) => {
      setCharacter({
        ...character,
        [name]: value
      })
    }

    const SetInventoryValue = (valueType: string, value: number) => {
        setCharacter({
            ...character,
            inventory: {
                ...character.inventory,
                [valueType]: value,
            }
        })
    }

    const setLimitedUse = (key: string, max: number) => {
        const limitedUse: MaxedValue = { current: max, max};
        const limitedUses = { ...character.limitedUses };
        limitedUses[key] = limitedUse

        setCharacter((character) => {
            return {
                ...character,
                limitedUses
            }
        })
    }

    const removeLimitedUse = (key: string) => {
        const limitedUses = { ...character.limitedUses };
        delete limitedUses[key];
        setCharacter((character) => {
            return {
                ...character,
                limitedUses
            }
        })
    }

    

    const playPanel = <Stack spacing={2}>
        <Section title="Attributes" collapsable>
            <Grid container spacing={1} marginBottom={2}>
            {display.attributes.map((attr) => {
                return <AttributeDisplay value={attr} />
            })}
            </Grid>
            {/* <SubStatsDisplay character={display} /> */}
            <EnduranceDamageView character={character} characterDisplay={display} setDamage={(value) => SetValue("enduranceDamageTaken", value)} />
        </Section>
        <DamageDisplay character={character} setCharacter={setCharacter} />
        <Section title="Weapons" collapsable>
            <WeaponDisplay characterDisplay={display} character={character} setSelected={() => undefined} />
        </Section>
        <Section title="Outfits" collapsable>
            <OutfitDisplay characterDisplay={display} setSelected={() => undefined} />
        </Section>
        <Section title="Abilities" collapsable>
            <AbilityDisplay abilities={display.strongHits} />
        </Section>
    </Stack>

    const influenceMax = display.level + 3 + GetAdjustment(InfluenceMax.id);
    const resourceMax = display.level + 3 + GetAdjustment(ResourcesMax.id);
    const inventoryPanel = <>
            <Section title="Inventory">
                <Box>
                <Typography fontWeight={700} paddingLeft={1}>Resources</Typography>
                    <Grid container>
                        <Grid item xs={6}>
                            <Bar label='Money'>
                                <NumberField step={1} onChange={(value) => SetInventoryValue('money', value)} value={display.inventory.money} min={0} />
                            </Bar>
                        </Grid>
                        <Grid item xs={6}>
                            <Bar label='Loot'>
                                <NumberField step={1} onChange={(value) => SetInventoryValue('loot', value)} value={display.inventory.loot} min={0} />
                            </Bar>
                        </Grid>
                        <Grid item xs={6}>
                            <Bar label='Freight'>
                                <NumberField step={1} onChange={(value) => SetInventoryValue('freight', value)} value={display.inventory.freight} min={0} />
                            </Bar>
                        </Grid>
                        <Grid item xs={6}>
                            <Bar label='Current Resources'>
                                <NumberField step={1} max={resourceMax} customRenderer={(value) => `${value} / ${resourceMax}`} onChange={(value) => SetInventoryValue('currentResources', value)} value={display.inventory.currentResources} min={0} />
                            </Bar>
                        </Grid>
                    </Grid>
                </Box>
                <Box marginTop={2}>
                    <Typography fontWeight={700} paddingLeft={1}>Research</Typography>
                    <Grid container>
                        <Grid item xs={6}>
                            <Bar label='Research Units'>
                                <NumberField step={1} onChange={(value) => SetInventoryValue('researchUnits', value)} value={display.inventory.researchUnits} min={0} />
                            </Bar>
                        </Grid>
                        <Grid item xs={6}>
                            <Bar label='Current Knowledge'>
                                <NumberField step={1} onChange={(value) => SetInventoryValue('currentKnowledge', value)} value={display.inventory.currentKnowledge} min={0} />
                            </Bar>
                        </Grid>
                    </Grid>
                </Box>
                <Box marginTop={2}>
                    <Typography fontWeight={700} paddingLeft={1}>Networking</Typography>
                    <Grid container>
                        <Grid item xs={6}>
                            <Bar label='Networking Units'>
                                <NumberField step={1} onChange={(value) => SetInventoryValue('networkUnits', value)} value={display.inventory.networkUnits} min={0} />
                            </Bar>
                        </Grid>
                        <Grid item xs={6}>
                            <Bar label='Current Influence'>
                                <NumberField step={1} max={influenceMax} customRenderer={(value) => `${value} / ${influenceMax}`} onChange={(value) => SetInventoryValue('currentInfluence', value)} value={display.inventory.currentInfluence} min={0} />
                            </Bar>
                        </Grid>
                    </Grid>
                </Box>
            </Section>
            <EquipmentList character={character} characterDisplay={display} setCharacter={setCharacter} />
    </>;
    
    const statsPanel = <Stack spacing={1}>
        <Section title="Skills" collapsable>
            <Typography fontWeight={700} marginBottom={1} paddingLeft={1}>Primary</Typography>
            <Grid container>
            {display.primarySkills.map((a, index) => {
                return <SkillField
                key={a.id}
                    showExtended={true}
                    onChange={(skill) => SetSkill(skill)}
                    value={a}
                    last={index === display.primarySkills.length-1}
                    character={display}
                    toolboxEnabled
                    />
                })}
            </Grid>
            <Typography fontWeight={700} marginBottom={1} paddingLeft={1}>Personal Combat</Typography>
            <Grid container>
            {display.combatSkills.map((a, index) => {
                return <SkillField
                    key={a.id}
                    toolboxEnabled={false}
                    showExtended={true}
                    onChange={(skill) => SetCombatSkill(skill)}
                    value={a}
                    last={index === display.combatSkills.length-1}
                    character={display}
                    />
            })}
            </Grid>
            <Typography fontWeight={700} marginBottom={1} paddingLeft={1}>Spaceship Combat</Typography>
            <Grid container>
            {display.spaceSkills.map((a, index) => {
                return <SkillField
                key={a.id}
                toolboxEnabled={false}
                showExtended={true}
                onChange={(skill) => SetSpaceSkill(skill)}
                value={a}
                last={index === display.spaceSkills.length-1}
                character={display}
                />
            })}
            </Grid>
        </Section>
        <Section title="Attributes" collapsable>
            <Grid container>
                {character.attributes.map((a, index) => 
                    <AttributeField character={character} value={a} key={a.id} last={index === display.attributes.length-1} onChange={(attribute) => SetAttribute(attribute)} />)}
            </Grid>
            <SubStatsDisplay character={display} />
        </Section>
        <TraitDisplay title="Traits" traits={character.traits} character={character} onChange={(traits) => SetValue("traits", traits)} />
        <TraitDisplay title="Complications" traits={character.complications} character={character} onChange={(traits) => SetValue("complications", traits)} />
        <TraitDisplay title="Perks" traits={character.perks} character={character} onChange={(traits) => SetValue("perks", traits)} />
        <Section title="Notes" collapsable>
            <TextField value={character.notes} onChange={(event) => SetNotes(event.currentTarget.value)} multiline fullWidth />
        </Section>
    </Stack>
    
    const detailsPanel = <Stack spacing={1}>
        <Section title="Character Details">
          <Stack direction={"column"} spacing={2}>
            <Box>
                <Typography>Name</Typography>
                <TextField value={character.name} onChange={(value) => SetValue("name", value.currentTarget.value)} fullWidth />
            </Box>
            <Box>
                <Typography>Backstory</Typography>
                <TextField value={character.backstory} onChange={(value) => SetValue("backstory", value.currentTarget.value)} multiline fullWidth />
            </Box>
            <Box>
                <Typography>Species</Typography>
                <SpeciesEditor character={character} onSpeciesChange={(value) => SetValue("species", value)} />
                <Select
                    value={character.subculture}
                    onChange={(e) => SetValue("subculture", e.target.value)}>
                    {SpeciesList[character.species.id]?.subcultures.map((sc) => <MenuItem value={sc}>{sc}</MenuItem>)}
                </Select>
            </Box>
          </Stack>
        </Section>
        <Section title={`Level ${display.level}`}>
          <Grid container>
              <Grid item xs={6}>
                  <Bar label='Competence'>
                      <NumberField step={1} onChange={(value) => SetValue("competence", value)} value={display.competence} min={0} />
                  </Bar>
              </Grid>
              <Grid item xs={6}>
                  <Bar label='Competence per level'>
                      <NumberField step={1} onChange={(value) => SetValue('competencePerLevel', value)} value={display.competencePerLevel} min={1} />
                  </Bar>
              </Grid>
          </Grid>
        </Section>
        <Bar label="Export Character">
            <Button onClick={() => exportCharacter(character)}>Export</Button>
        </Bar>
        <Bar label="Manage Character">
            <DeleteCharacterButton character={character} />
        </Bar>
    </Stack>

    return <>
        <ErrorBoundary fallback={(error) => <>
            <Page>
                <Typography paddingY={3} variant="h4">There was an error displaying this character.</Typography>
                <Typography >{error.componentStack}</Typography>
                </Page></>}>
            <CharacterContext.Provider value={{ removeLimitedUse, setLimitedUse, character, characterDisplay: display, getAdjustment: GetAdjustment}}>
                <Page>
                    <Stack spacing={1} direction={"row"}>
                        <Box flexGrow={1} display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
                            <Typography variant="h3" fontWeight={700} fontSize={28} textAlign="center" style={{ overflowWrap: "anywhere"}}>{character.name}</Typography>
                            <Typography fontSize={15} textAlign="center" style={{ overflowWrap: "break-word"}}>Lvl {display.level}, {character.species.name} {character.subculture}</Typography>
                        </Box>
                        <img src={character.species.image} style={{ width:"90px", aspectRatio:"1/1", flexShrink: 0}} />
                    </Stack>
                <TabBar pages={[
                    { label: <><CharacterIcon height={"2em"} /></>, content: statsPanel },
                    { label: <><CritDamageIcon height={"2em"} /></>, content: playPanel},
                    { label: <><RecordIcon height={"2em"} /></>, content: inventoryPanel },
                    { label: <><MetaIcon height={"2em"} /></>, content: detailsPanel}
                ]} />
                </Page>
            </CharacterContext.Provider>
        </ErrorBoundary>
    </>
}
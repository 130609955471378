import { useEffect, useState } from "react";
import { TradeGood } from "../../../model/character";
import { v4 } from 'uuid';

import { Box, Button, Chip, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormGroup, Stack, TextField } from "@mui/material";
import { NumberField } from "../../../components/formFields/NumberField";
import StatLookupField from "../../../components/StatLookupField";
import FormFieldLabel from "../../../components/formFields/FormFieldLabel";
import { Modal } from "../../../components/Modal";

const defaultTradeGood: TradeGood = {
    amount: 1,
    conversion: 1,
    weight: 1,
    slots: 0,
    description: "",
    id: "",
    name: "New Trade Good",
    resourceType: "",
    isAcquired: false,
    isStashed: false,
}

export const GetNewTradeGood = (): TradeGood => {
    return {
        ...defaultTradeGood,
        id: v4()
    }
}

interface TradeGoodEditorProps {
    tradeGood?: TradeGood;
    setTradeGood: (tradeGood: TradeGood) => void;
    onClose: () => void;
}

const TradeGoodEditor = (props: TradeGoodEditorProps      
    ) => {
    const { tradeGood, setTradeGood, onClose } = props;
    const [ current, setCurrent ] = useState<TradeGood>(defaultTradeGood);

    useEffect(() => {
        if(tradeGood !== undefined)
            setCurrent(tradeGood);
    }, [tradeGood]);

    const onChange = (key: string, value: any) => {
        setCurrent({
            ...current,
            [key]: value
        })
    }

    const handleCancel = () => {
        onClose();
    }

    const handleSave = () => {
        setTradeGood(current);
        onClose();
    }

    return <Modal
        title={`Edit ${current.name}`}
        open={tradeGood !== undefined} onClose={handleCancel}
        actions={[
            {
                label: "Cancel",    
                onClick: handleCancel,
            },
            {
                label: "Save",    
                onClick: handleSave,
                primary: true,
            },
        ]}
        >
        <FormGroup>
            <Stack spacing={1}>
            <FormFieldLabel label="Name">
                <TextField fullWidth type="text" variant="standard" value={current.name} onChange={(event) => onChange("name", event.currentTarget.value)} />
            </FormFieldLabel>
            <FormFieldLabel label="Description">
                <TextField fullWidth type="text" variant="standard" value={current.description} onChange={(event) => onChange("description", event.currentTarget.value)} />
            </FormFieldLabel>
            <FormFieldLabel label="Amount" justifyContent="end">
                <NumberField min={0} step={1} value={current.amount} onChange={(value) => onChange("amount", value)} />
            </FormFieldLabel>
            <FormFieldLabel label="Slot Conversion" justifyContent="end"> 
                <NumberField min={0} value={current.conversion} onChange={(value) => onChange("conversion", value)} />
            </FormFieldLabel>
            <FormFieldLabel label="Weight" justifyContent="end">
                <NumberField min={0} value={current.weight} onChange={(value) => onChange("weight", value)} />
            </FormFieldLabel>
            <FormFieldLabel label="Resource Type">
                <StatLookupField onChange={(value) => onChange("resourceType", value)} value={current.resourceType} stats={[
                                    { id: "resources", name: "Resources"},
                                    { id: "influence", name: "Influence"},
                                    { id: "knowledge", name: "Knowledge"}
                                ]} />
            </FormFieldLabel>
            </Stack>
        </FormGroup>
    </Modal>
}

export default TradeGoodEditor;
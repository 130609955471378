import { Box, ClickAwayListener, Tooltip, Typography, useTheme } from "@mui/material";
import { ReactNode, useState } from "react";

import { ReactComponent as LeftBunt } from '../images/Heading_EvenBuntLeft.svg';
import { ReactComponent as RightBunt }  from '../images/Heading_EvenBuntRight.svg';

interface HeadingBarProps {
    children?: ReactNode
    extendBunts?: boolean;
    center?: boolean;
}

export const HeadingBarHeader = (props: { children?: ReactNode, tooltip?: string}) => {
    const [ open, setOpen ] = useState(false);

    return <Tooltip title={props.tooltip} open={open} onClick={() => setOpen(true)} >
        <>{props.children}</>
    </Tooltip>;
    return <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Tooltip title={props.tooltip} open={open} onClick={() => setOpen(true)} >
            <>{props.children}</>
        </Tooltip>
    </ClickAwayListener>
}

const HeadingBar = (props: HeadingBarProps) => {
    const { children, extendBunts, center } = props;
    const theme = useTheme();

    return <Box display="flex" height={"20px"} marginX={extendBunts ? "-6px" : undefined}>
        <LeftBunt fill={theme.palette.grey[800]} />
        <Box height={"100%"} flexGrow={1} sx={{backgroundColor: theme.palette.grey[800]}} textAlign={center ? "center" : undefined} fontSize={15} color={theme.palette.primary.contrastText}>{children}</Box>
        <RightBunt fill={theme.palette.grey[800]} />
    </Box>
}

export default HeadingBar;
import { Box, ClickAwayListener, Tooltip } from "@mui/material";
import { ReactNode, useState } from "react";

interface SimpleTableProps {
    entries: ReactNode[];
}

const SimpleTable = (props: SimpleTableProps) => {
    const { entries } = props;
    return <>{entries.map((entry, index) => {
        return <Box sx={{ backgroundColor: index % 2 == 1 ? "#deecc5" : undefined}} padding={1}>{entry}</Box>
    })}</>
}

export default SimpleTable;

export const SimpleTableHeader = (props: { children?: ReactNode, tooltip?: string}) => {
    const [ open, setOpen ] = useState(false);

    return <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Tooltip title={props.tooltip} open={open} onClick={() => setOpen(true)} >
            <>{props.children}</>
        </Tooltip>
    </ClickAwayListener>
}
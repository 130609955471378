import { PlayerCharacter, Equipment } from "../../../model/character";

import { Box, Button, Chip, FormGroup, Stack, TextField, Typography } from "@mui/material";

import { ReactNode, useEffect, useState } from "react";
import { v4 } from "uuid";
import AbilityEditor from "../AbilityEditor";
import { NumberField } from "../../../components/formFields/NumberField";
import { Modal, ModalContentSection } from "../../../components/Modal";
import FormFieldLabel from "../../../components/formFields/FormFieldLabel";


const defaultUtility: Equipment = {
    id: "",
    description: "",
    acquire: "",
    adjustments: [],
    features: [],
    influence: 0,
    knowledge: 0,
    resources: 0,
    slots: 1,
    isAcquired: false,
    name: "New Utility",
    strongHits: [],
    isStashed: false,
    draw: 0,
}

export const GetNewUtility = (name?: string): Equipment => {
    return {
        ...defaultUtility,
        name: name ?? defaultUtility.name,
        id: v4()
    }
}

export const UtilityEditor = (props: {
    setUtility: (value: Equipment) => void,
    onClose: () => void,
    utility?: Equipment;
    character: PlayerCharacter,
}) => {
    const { utility, setUtility, onClose } = props;
    const [ current, setCurrent ] = useState<Equipment>(defaultUtility);
    const [ feature, setFeature ] = useState("");

    useEffect(() => {
        if(utility !== undefined)
            setCurrent(utility ?? defaultUtility);
    }, [utility]);

    const handleModalCancel = () => {
        onClose();
    }

    const handleModalSubmit = () => {
        setUtility(current)
    }

    const handleAddFeature = () => {
        const newFeatures = [ ...current.features, feature ];
        onChange("features", newFeatures);
        setFeature("");
    }

    const handleRemoveFeature = (index: number) => {
        const features = [ ...current.features ];
        features.splice(index, 1);
        onChange("features", features);
    }

    const onChange = (key: string, value: any) => {
        setCurrent((c) => ({
            ...c,
            [key]: value
        }))
    }
    
    return <>
        <Modal
            open={utility !== undefined}
            onClose={handleModalCancel}
            actions={[
                {
                    label: "Cancel",    
                    onClick: handleModalCancel,
                },
                {
                    label: "Save",    
                    onClick: handleModalSubmit,
                    primary: true,
                },
            ]}
            title="Edit Utility"
            >
            <FormGroup>
                <Stack spacing={1}>
                <FormFieldLabel label="Name">
                    <TextField type="text" variant="standard" fullWidth value={current.name} onChange={(event) => onChange("name", event.currentTarget.value)} />
                </FormFieldLabel>
                <Box paddingLeft={1}>
                    <Typography>Description</Typography>
                    <TextField value={current.description} onChange={(event) => onChange('description', event.currentTarget.value)} multiline fullWidth />
                </Box>
                <FormFieldLabel label="Slots" justifyContent="end">
                    <NumberField min={0} step={1} value={current.slots} onChange={(value) => onChange("slots", value)} />
                </FormFieldLabel>
                <FormFieldLabel label="Draw" justifyContent="end">
                    <NumberField min={0} step={1} value={current.draw} onChange={(value) => onChange("draw", value)} />
                </FormFieldLabel>
                <FormFieldLabel label="Influence" justifyContent="end">
                    <NumberField step={1} value={current.influence} onChange={(value) => onChange("influence", value)} />
                </FormFieldLabel>
                <FormFieldLabel label="Resources" justifyContent="end">
                    <NumberField step={1} value={current.resources} onChange={(value) => onChange("resources", value)} />
                </FormFieldLabel>
                <FormFieldLabel label="Knowledge" justifyContent="end">
                    <NumberField step={1} value={current.knowledge} onChange={(value) => onChange("knowledge", value)} />
                </FormFieldLabel>
                <FormFieldLabel label="Acquire">
                    <TextField type="text" variant="standard" fullWidth value={current.acquire} onChange={(event) => onChange("acquire", event.currentTarget.value)} />
                </FormFieldLabel>
                </Stack>
                <ModalContentSection title="Features">
                    <Stack direction="row">
                        <TextField sx={{flexGrow: 1}} type="text" variant="standard" value={feature} onChange={(event) => setFeature(event.currentTarget.value)} />
                        <Button onClick={handleAddFeature}>Add Feature</Button>
                    </Stack>
                    <Stack flexWrap="wrap" useFlexGap direction="row" spacing={1}>
                        {current.features.map((value, index) => <Chip onClick={() => handleRemoveFeature(index)} label={value} key={index} />)}
                    </Stack>
                </ModalContentSection>
                <AbilityEditor abilities={current.strongHits} setAbilities={(value) => onChange("strongHits", value)} />
            </FormGroup>
        </Modal>
    </>
}
import { Box, Icon, IconButton, Stack, TextField, Typography } from "@mui/material";

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { ReactNode } from "react";
import { ButtonFlatIcon } from "../ButtonFlat";


interface NumberFieldProps {
    value: number;
    min?: number;
    max?: number;
    step?: number;
    onChange: (value: number) => void;
    customRenderer?: (value: number) => ReactNode;
}

export const NumberField = (props: NumberFieldProps) => {
    const { value, min, max, step, onChange, customRenderer } = props;
    
    const showValue = typeof(value) !== "number" || isNaN(value) ? 0 : value;

    const handleSet = (value: string) => {
        try{
            let numberValue = Number.parseFloat(value);
            if(min !== undefined && numberValue < min) numberValue = min;
            if(max !== undefined && numberValue > max) numberValue = max;
            onChange(numberValue);
        } catch (e: any) {
            console.error("Invalid number");
        }
    }

    const handleAdd = () => {
        if(max !== undefined && showValue >= max) return;
        onChange(showValue + (step ?? 1));
    }

    const handleSubtract = () => {
        if(min !== undefined && showValue <= min) return;
        onChange(showValue - (step ?? 1));
    }

    if(step === undefined) {
        return <TextField variant="standard" value={showValue} type="number" onChange={(event) => handleSet(event.currentTarget.value)} />
    }


    return <Stack direction="row" spacing={1} display="inline-flex" lineHeight={1} height="33px">
        <Box width="50px" border="1px solid #000" display="flex" alignItems="center" justifyContent="center">
            <Typography display="inline" textOverflow="clip">{customRenderer === undefined ? showValue : customRenderer!(showValue)}</Typography>
        </Box>
        <ButtonFlatIcon onClick={() => handleSubtract()}><RemoveIcon /></ButtonFlatIcon>
        <ButtonFlatIcon onClick={() => handleAdd()}><AddIcon /></ButtonFlatIcon>
    </Stack>
}
import { Box, InputBase, useTheme } from "@mui/material";

interface TextFieldProps {
    value: string;
    onChange: (value: string) => void;
    placeholder?: string;
}

export const TextField = (props: TextFieldProps) => {
    const theme = useTheme();
    const { value, onChange, placeholder } = props;

    return <Box borderBottom={`1px solid ${theme.palette.grey[500]}`} paddingX={1}>
        <InputBase placeholder={placeholder} value={value} onChange={(event) => onChange(event.currentTarget.value)} />
    </Box>
        
}
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, Grid, Typography } from "@mui/material";
import { useContext, useState } from "react";

import EditIcon from '@mui/icons-material/Edit';
import { Attribute, PlayerCharacter } from "../model/character";
import { StatBox } from "./StatBox";
import { NumberField } from "./formFields/NumberField";
import { CharacterContext } from "../model/characterContext";

import HexagonIcon from '@mui/icons-material/Hexagon';

import styled from 'styled-components';

const Hexagon = styled.div`
    width: 55px;
    height: 55px;
    margin: 0 auto;
    position: relative;
`;

const HexagonNumber = styled.div`
    position: absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
    font-size: 20px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff
`;

const HexagonSide = styled.div`
    display: flex;
    position: absolute;
    left: 65px;
    top: 0px;
    bottom: 0px;
    width:15px;
    flex-direction: column;
    justify-content: center;
    &>*:not(:first-child) {
        margin-top: 5px;
    }
`;

interface AttributeFieldProps {
    value: Attribute;
    showExtended?: boolean;
    last?: boolean;
    character: PlayerCharacter;
    onChange: (value: Attribute) => void;
}

export const AttributeField = (props: AttributeFieldProps) => {
    const characterContext = useContext(CharacterContext)!;
    const { value, character, onChange } = props;

    const [ attributeValue, setAttributeValue ] = useState(value);
    const [ editing, setEditing ] = useState(false);

    const handleSubmit = () => {
        onChange(attributeValue);
        setEditing(false);
    }

    const handleCancel = () => {
        setAttributeValue(value);
        setEditing(false);
    }

    //const total = (value.trained ? 1: -2) + (value.toolbox ? 1 : 0) + (value.workshop ? 1 : 0);
    const max = attributeValue.max + characterContext.getAdjustment(`${value.id}_max`);
    const bonus = characterContext.getAdjustment(`${value.id}`)

    let skillsLeft = 18;
    for(var attr of character.attributes) {
        if(attr.id !== attributeValue.id)
            skillsLeft -= attr.alloted;
    }

    const current = Math.min(props.value.alloted, max) + bonus;

    return <>
        <Dialog open={editing} onClose={handleCancel}>
            <DialogTitle>Attribute: {value.name}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Edit this attribute.<br />
                    Skills left: {skillsLeft - attributeValue.alloted}
                </DialogContentText>
                <FormGroup>
                    <FormControlLabel control={<NumberField step={1} min={0} max={Math.min(max, skillsLeft)} value={attributeValue.alloted} onChange={(v) => setAttributeValue(current => { return { ...current, alloted : v}} )} />} label="Alloted" />
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={handleSubmit}>Modify</Button>
            </DialogActions>
        </Dialog>
        <Grid item xs={4} sm={4} onClick={() => setEditing(true)} marginBottom={2}>
            <Hexagon>
                <HexagonNumber>
                    <Box fontSize="55px"><HexagonIcon fontSize="inherit" color="primary" /></Box>
                </HexagonNumber>
                <HexagonNumber>
                    {current}
                </HexagonNumber>
            </Hexagon>
            <Typography variant="body1" textAlign={"center"}>{value.name}</Typography>
        </Grid>
    </>
}
import { Box, Collapse, Grid, Stack, Typography } from "@mui/material";
import { CharacterSpecies, PlayerCharacter } from "../model/character";
import { SpeciesData, SpeciesList } from "../model/species";

import { ReactComponent as IconEdit } from '../components/icons/raw/Icon_Edit.svg'
import { useState } from "react";

interface SpeciesSelectorProps {
    character: PlayerCharacter;
    onSpeciesChange: (species: CharacterSpecies) => void;
}

const SpeciesItem = (props: {data: SpeciesData, onClick: () => void, selected: boolean} ) => {
    return <Grid item xs={4} md={3} sx={{opacity: props.selected ? 1 : 0.7}}>
        <img onClick={props.onClick} style={{ aspectRatio: "1:1", width:"100%", cursor:"pointer", display:"block"}} src={props.data.image} />
    </Grid>
}

interface SpeciesItemsProps {
    species: string;
    onSpeciesChange: (species: CharacterSpecies) => void;
}

export const SpeciesItems = (props: SpeciesItemsProps) => {
    const {species, onSpeciesChange} = props;

    const current = SpeciesList[species];

    return <>
        <Grid container spacing={1}>
            {Object.keys(SpeciesList).map((key) => {
                const data = SpeciesList[key];
                return <SpeciesItem selected={key == species} key={key} data={data} onClick={() => {
                    onSpeciesChange({
                        id: key,
                        adjustments: [],
                        color: data.color,
                        description: data.description,
                        image: data.image,
                        name: data.name,
                        strongHits: []
                    });
                }} />
            })}
        </Grid>
        <Typography marginTop={1} paddingLeft={1} fontStyle="italic"><strong>{current.name}:</strong> "{current.description}"</Typography>
        </>
}

const SpeciesEditor = (props: SpeciesSelectorProps) => {
    const { character, onSpeciesChange } = props;
    const [ expanded, setExpanded ] = useState(false);

    return <>
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
            <img src={character.species.image} style={{ flexShrink: 0, width: "140px", aspectRatio: "1:1"}}/>
            <Box flexGrow={1} textAlign={"center"}>
                <Typography fontSize={22} fontWeight={700}>{character.species.name} <IconEdit height={"1em"} onClick={() => setExpanded(!expanded)} /></Typography>
                {character.species.description}
            </Box>
        </Stack>
        <Collapse in={expanded}>
            <Typography marginTop={2} fontSize={22} fontWeight={700}>Select your species</Typography>
            <SpeciesItems species={character.species.id} onSpeciesChange={(value) => { 
                setExpanded(false); onSpeciesChange(value); }} />
        </Collapse>
    </>
}

export default SpeciesEditor;
import { ReactNode, useEffect, useState } from "react";
import SimpleTable from "../../../components/SimpleTable";
import { PlayerCharacter, PlayerCharacterDisplay, Weapon, WeaponBlock, WeaponBlockBase } from "../../../model/character";
import { v4 } from 'uuid';

import { Box, Button, Chip, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormGroup, Grid, Stack, TextField, Typography } from "@mui/material";
import { NumberField } from "../../../components/formFields/NumberField";
import { TabBar } from "../../../components/TabBar";
import AbilityEditor from "../AbilityEditor";
import StatLookupField from "../../../components/StatLookupField";
import FormFieldLabel from "../../../components/formFields/FormFieldLabel";
import { Modal, ModalContentSection } from "../../../components/Modal";

interface WeaponListProps {
    character: PlayerCharacter;
    characterDisplay: PlayerCharacterDisplay;
    setCharacter: (character: PlayerCharacter) => void;
}

const WeaponBlockEditor = (props: {
        weaponBlock: WeaponBlock | WeaponBlockBase,
        setWeaponBlock: (weaponBlock: WeaponBlock | WeaponBlockBase) => void,
        character: PlayerCharacter }        
    ) => {
    const { weaponBlock, setWeaponBlock, character } = props;
    const [ feature, setFeature ] = useState("");

    const onChange = (key: string, value: any) => {
        setWeaponBlock({
            ...weaponBlock,
            [key]: value
        })
    }

    const handleAddFeature = () => {
        const newFeatures = [ ...weaponBlock.addFeatures, feature ];
        onChange("addFeatures", newFeatures);
        setFeature("");
    }

    const handleRemoveFeature = (index: number) => {
        const features = [ ...weaponBlock.addFeatures ];
        features.splice(index, 1);
        onChange("addFeatures", features);
    }

    return <>
        <Stack spacing={1}>
            <FormFieldLabel label="Name">
                <TextField fullWidth type="text" variant="standard" value={weaponBlock.name} onChange={(event) => onChange("name", event.currentTarget.value)} />
            </FormFieldLabel>
            <FormFieldLabel label="Equipment Slots" justifyContent="end">
                <NumberField step={1} value={weaponBlock.slots} onChange={(value) => onChange("slots", value)} />
            </FormFieldLabel>
            <FormFieldLabel label="Hit Dice" justifyContent="end">
                <NumberField step={1} min={0} value={weaponBlock.hitDice} onChange={(value) => onChange("hitDice", value)} />
            </FormFieldLabel>
            <FormFieldLabel label="Hit Bonus" justifyContent="end">
                <NumberField step={1} value={weaponBlock.hitBonus} onChange={(value) => onChange("hitBonus", value)} />
            </FormFieldLabel>
            {'skillBonusId' in weaponBlock &&
            <FormFieldLabel label="Skill Bonus" justifyContent="end"><StatLookupField onChange={(value) => onChange("skillBonusId", value)} value={weaponBlock.skillBonusId} stats={[
                                ...character.combatSkills,
                            ]} /></FormFieldLabel> }
            <FormFieldLabel label="Range" justifyContent="end">
                <NumberField step={1} value={weaponBlock.range} onChange={(value) => onChange("range", value)} />
            </FormFieldLabel>
            <FormFieldLabel label="Endurance Damage" justifyContent="end">
                <NumberField step={1} value={weaponBlock.enduranceDamage} onChange={(value) => onChange("enduranceDamage", value)} />
            </FormFieldLabel>
            <FormFieldLabel label="Crit Damage" justifyContent="end">
                <NumberField step={1} value={weaponBlock.critDamage} onChange={(value) => onChange("critDamage", value)} />
            </FormFieldLabel>
            <FormFieldLabel label="Draw" justifyContent="end">
                <NumberField step={1} value={weaponBlock.draw} onChange={(value) => onChange("draw", value)} />
            </FormFieldLabel>
            <FormFieldLabel label="Strong Hit Range" justifyContent="end">
                <NumberField step={1} value={weaponBlock.strongHitRange} onChange={(value) => onChange("strongHitRange", value)} />
            </FormFieldLabel>
            <FormFieldLabel label="Reload" justifyContent="end">
                <NumberField step={1} value={weaponBlock.reload} onChange={(value) => onChange("reload", value)} />
            </FormFieldLabel>
            <FormFieldLabel label="Influence" justifyContent="end">
                <NumberField step={1} value={weaponBlock.influence} onChange={(value) => onChange("influence", value)} />
            </FormFieldLabel>
            <FormFieldLabel label="Resources" justifyContent="end">
                <NumberField step={1} value={weaponBlock.resources} onChange={(value) => onChange("resources", value)} />
            </FormFieldLabel>
            <FormFieldLabel label="Knowledge" justifyContent="end">
                <NumberField step={1} value={weaponBlock.knowledge} onChange={(value) => onChange("knowledge", value)} />
            </FormFieldLabel>
            <FormFieldLabel label="Acquire">
                <TextField fullWidth type="text" variant="standard" value={weaponBlock.acquire} onChange={(event) => onChange("acquire", event.currentTarget.value)} />
            </FormFieldLabel>
        </Stack>
        <ModalContentSection title="Features">
            <Stack direction="row">
                <TextField sx={{flexGrow: 1}} type="text" variant="standard" value={feature} onChange={(event) => setFeature(event.currentTarget.value)} />
                <Button onClick={handleAddFeature}>Add Feature</Button>
            </Stack>
            <Stack flexWrap="wrap" useFlexGap direction="row" spacing={1}>
                {weaponBlock.addFeatures.map((value, index) => <Chip onClick={() => handleRemoveFeature(index)} label={value} key={index} />)}
            </Stack>
        </ModalContentSection>
        <AbilityEditor abilities={weaponBlock.strongHits} setAbilities={(value) => onChange("strongHits", value)} />
    </>
}

const defaultBlock: WeaponBlock = {
    name: "Weapon",
    slots: 0,
    acquire: "",
    critDamage: 0,
    enduranceDamage: 0,
    hitDice: 0,
    hitBonus: 0,
    range: 0,
    resources: 0,
    knowledge: 0,
    influence: 0,
    weaponType: [],
    addFeatures: [],
    removeFeatures: [],
    strongHits: [],
    draw: 0,
    reload: 0,
    strongHitRange: 0,
}

const defaultWeapon: Weapon = {
    base: {
        name: "Base Weapon",
        slots: 2,
        acquire: "",
        critDamage: 0,
        enduranceDamage: 0,
        hitDice: 0,
        hitBonus: 0,
        range: 0,
        resources: 0,
        knowledge: 0,
        influence: 0,
        weaponType: [],
        addFeatures: [],
        removeFeatures: [],
        strongHits: [],
        skillBonusId: "",
        draw: 1,
        reload: 2,
        strongHitRange: 1,
    },
    variations: [],
    modifications: [],
    id: "",
    isAcquired: false,
    isHeld: false,
    isStashed: false,
    name: "New Weapon",
    description: ""
}

export const GetNewWeapon = (): Weapon => {
    return {
        ...defaultWeapon,
        id: v4()
    }
}

export const WeaponEditor = (props: {
    setWeapon: (value: Weapon) => void,
    onClose: () => void,
    weapon?: Weapon;
    character: PlayerCharacter,
}) => {
    const { weapon, character, setWeapon, onClose } = props;
    const [ current, setCurrent ] = useState<Weapon>(defaultWeapon);

    useEffect(() => {
        if(weapon !== undefined)
            setCurrent(weapon ?? defaultWeapon);
    }, [weapon]);

    const handleModalCancel = () => {
        onClose();
    }

    const handleModalSubmit = () => {
        setWeapon(current)
    }

    const handleAddVariation = () => {
        const variations = [ ...current.variations, { ...defaultBlock, name: "New Variation" } ]
        setCurrent((c) => ({
            ...c,
            variations
        }));
    }

    const handleRemoveVariation = (index: number) => {
        const variations = [ ...current.variations ];
        variations.splice(index,1);
        setCurrent((c) => ({
            ...c,
            variations
        }));
    }

    const handleChangeVariation = (index: number, variation: WeaponBlock) => {
        const variations = [ ...current.variations ];
        variations[index] = variation;
        setCurrent((c) => ({
            ...c,
            variations
        }));
    }

    const handleAddModification = () => {
        const modifications = [ ...current.modifications, { ...defaultBlock, name: "New Modification" } ]
        setCurrent((c) => ({
            ...c,
            modifications
        }));
    }

    const handleRemoveModification = (index: number) => {
        const modifications = [ ...current.modifications ];
        modifications.splice(index,1);
        setCurrent((c) => ({
            ...c,
            modifications
        }));
    }

    const handleChangeModification = (index: number, modification: WeaponBlock) => {
        const modifications = [ ...current.modifications ];
        modifications[index] = modification;
        setCurrent((c) => ({
            ...c,
            modifications
        }));
    }
    
    const onChange = (key: string, value: any) => {
        setCurrent((c) => ({
            ...c,
            [key]: value
        }))
    }

    return <Modal
        title={current.name}
        open={weapon !== undefined}
        onClose={handleModalCancel}
        actions={[
            {
                label: "Cancel",    
                onClick: handleModalCancel,
            },
            {
                label: "Save",    
                onClick: handleModalSubmit,
                primary: true,
            },
        ]}
        >
            <FormGroup>
                <FormFieldLabel label="Name">
                    <TextField fullWidth type="text" variant="standard" value={current.name} onChange={(event) => onChange("name", event.currentTarget.value)} />
                </FormFieldLabel>
                <Box paddingLeft={1}>
                    <Typography>Description</Typography>
                    <TextField value={current.description} onChange={(event) => onChange('description', event.currentTarget.value)} multiline fullWidth />
                </Box>
                <TabBar pages={[
                    { label: "Base", content: <WeaponBlockEditor character={character} weaponBlock={current.base} setWeaponBlock={(wb) => onChange("base", wb)} /> },
                    { label: "Variations", content: <>
                        {current.variations.map((variation, index) => {
                            return <div key={index}>
                                <WeaponBlockEditor character={character} weaponBlock={variation} setWeaponBlock={(wb) => handleChangeVariation(index, wb)} />
                                <Button onClick={() => handleRemoveVariation(index)}>Remove {variation.name}</Button>
                            </div>
                        })}
                        <Button onClick={handleAddVariation}>Add Variation</Button>
                    </> },
                    { label: "Mods", content: <>
                        {current.modifications.map((modification, index) => {
                            return <div key={index}>
                                <WeaponBlockEditor character={character} weaponBlock={modification} setWeaponBlock={(wb) => handleChangeModification(index, wb)} />
                                <Button onClick={() => handleRemoveModification(index)}>Remove {modification.name}</Button>
                            </div>
                        })}
                        <Button onClick={handleAddModification}>Add Modification</Button>
                    </> },
                ]} />
            </FormGroup>
        </Modal>
}
import { Grid } from "@mui/material";
import SimpleTable, { SimpleTableHeader } from "../../components/SimpleTable";
import { Ability } from "../../model/character";
import HeadingBar from "../../components/HeadingBar";

interface AbilityDisplayProps {
    abilities: Ability[];
}

const AbilityDisplay = (props: AbilityDisplayProps) => {
    const { abilities: strongHits } = props;

    return <>
        <HeadingBar extendBunts>
            <Grid container  paddingX={1}>
                <Grid item xs={3}><SimpleTableHeader>Name</SimpleTableHeader></Grid>
                <Grid item xs={2}><SimpleTableHeader tooltip="Requirements">Rq</SimpleTableHeader></Grid>
                <Grid item xs={2}><SimpleTableHeader tooltip="Results">Re</SimpleTableHeader></Grid>
                <Grid item xs={2}><SimpleTableHeader tooltip="Limits">Li</SimpleTableHeader></Grid>
                <Grid item xs={3}><SimpleTableHeader tooltip="Uses of the strong hit">Uses</SimpleTableHeader></Grid>
            </Grid>
        </HeadingBar>
        <SimpleTable entries={strongHits.map((sh) => {
            return <Grid container paddingX={1}>
                <Grid item xs={3}>{sh.name}</Grid>
                <Grid item xs={2}>{sh.requirements}</Grid>
                <Grid item xs={2}>{sh.results}</Grid>
                <Grid item xs={2}>{sh.limits}</Grid>
                <Grid item xs={3}>{sh.limitedUseMax}</Grid>
            </Grid>
        })} />
    </>
}

export default AbilityDisplay;
import { ReactComponent as Icon } from './raw/Outfit_kevlar-vest.svg'
import { SvgIcon, SvgIconProps } from "@mui/material";

const OutfitIcon = (props: SvgIconProps) => {
    return (
      <SvgIcon {...props}>
        <Icon />
      </SvgIcon>
    );
  }

export default OutfitIcon;
import { Grid } from "@mui/material";
import { Outfit, OutfitDisplay, Weapon, WeaponDisplay } from "../../model/character"

export interface WeaponDetailDisplayProps {
    weapon: Weapon;
    weaponDisplay: WeaponDisplay;
}

const WeaponDetailDisplay = (props: WeaponDetailDisplayProps) => {
    const { weapon, weaponDisplay } = props;
    return <Grid container marginTop={2}>
        <Grid item xs={12}>{weapon.description}</Grid>
        <Grid item xs={8} md={4}>Damage:</Grid>
        <Grid item xs={4} md={2}>{weaponDisplay.enduranceDamage}</Grid>
        <Grid item xs={8} md={4}>Crit Damage:</Grid>
        <Grid item xs={4} md={2}>{weaponDisplay.critDamage}</Grid>

        <Grid item xs={8} md={4}>Hit Bonus:</Grid>
        <Grid item xs={4} md={2}>{weaponDisplay.hitBonus}</Grid>
        <Grid item xs={8} md={4}>Hit Dice:</Grid>
        <Grid item xs={4} md={2}>{weaponDisplay.hitDice}</Grid>

        <Grid item xs={8} md={4}>Reload:</Grid>
        <Grid item xs={4} md={2}>{weaponDisplay.reload}</Grid>
        <Grid item xs={8} md={4}>Strong Hit Range:</Grid>
        <Grid item xs={4} md={2}>{weaponDisplay.strongHitRange}</Grid>

        <Grid item xs={8} /*md={4}*/>Draw:</Grid>
        <Grid item xs={4} /*md={2}*/>{weaponDisplay.draw}</Grid>

        <Grid item xs={8}>Weapon types:</Grid>
        <Grid item xs={4}>{weaponDisplay.weaponType.length === 0 ? "None" : weaponDisplay.weaponType.join(", ")}</Grid>
        <Grid item xs={8}>Features:</Grid>
        <Grid item xs={4}>{weaponDisplay.features.length === 0 ? "None" : weaponDisplay.features.join(", ")}</Grid>

        <Grid item xs={8}>Modifications:</Grid>
        <Grid item xs={4}>{weapon.modifications.length ?? 0}</Grid>
        <Grid item xs={12}>{weapon.modifications.map((mod) => mod.name).join(", ")}</Grid>
        <Grid item xs={8}>Variations:</Grid>
        <Grid item xs={4}>{weapon.variations.length ?? 0}</Grid>
        <Grid item xs={12}>{weapon.variations.map((mod) => mod.name).join(", ")}</Grid>
    </Grid>
}

export default WeaponDetailDisplay;
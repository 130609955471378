import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormGroup, Grid, IconButton, TextField, Typography } from "@mui/material";
import { PlayerCharacter, Ability, Trait } from "../../model/character"
import { useEffect, useState } from "react";
import StatLookupField from "../../components/StatLookupField";
import { v4 as uuidv4 } from 'uuid';

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { NumberField } from "../../components/formFields/NumberField";
import Section from "../../components/Section";
import { AllStats, ArmorAtZeroStat, ArmorStat, AugmentedNumber, EnduranceCurrentStat, EnduranceDamage, EnduranceMaxStat, EquipmentCurrentStat, EquipmentMaxStat, GritReroll, HitBonus, InfluenceMax, ResourcesMax, UtilitiesMax } from "../../model/statsConstant";
import AbilityEditor from "./AbilityEditor";
import { ModalContentSection } from "../../components/Modal";

export interface TraitDisplayProps {
    title: string;
    character: PlayerCharacter;
    traits: Trait[];
    onChange: (traits: Trait[]) => void;
}

const defaultTrait: Trait = {
    adjustments: [],
    strongHits: [],
    description: "",
    id: "",
    name: "New Trait",
}

const createTrait = (): Trait => {
    return {
        ...defaultTrait,
        id: uuidv4()
    }
}

const EditModal = (props: { 
        value: Trait,
        show: boolean,
        character: PlayerCharacter,
        onChange: (trait: Trait) => void,
        onClose: () => void,
    }) => {
    const { value, show, character, onClose, onChange } = props;

    const [ current, setCurrent ] = useState<Trait>({ ...defaultTrait, ...value });

    useEffect(() => {
        setCurrent({ ...defaultTrait, ...value });
    }, [value])
    

    const handleCancel = () => {
        onClose();
    }

    const handleSubmit = () => {
        onChange(current);
    }

    const onChangeName = (value: string) =>{
        setCurrent({
            ...current,
            name: value,
        })
    }
    const onChangeDescription = (value: string) =>{
        setCurrent({
            ...current,
            description: value,
        })
    }

    const onChangeStrongHits = (value: Ability[]) => {
        setCurrent({
            ...current,
            strongHits: value
        })
    }

    const handleAddAdjustment = () => {
        
        setCurrent({
            ...current,
            adjustments: [...current.adjustments, {
                adjustment: 0,
                targetId: ""
            }]
        })
    }

    const handleAdjustmentTarget = (index: number, id: string) => {
        const skills = current.adjustments;
        skills[index].targetId = id;
        setCurrent({ ...current, adjustments: skills });
    }

    const handleChangeAdjustmentValue = (index: number, adjustment: number) => {
        const skills = current.adjustments;
        skills[index].adjustment = adjustment;
        setCurrent({ ...current, adjustments: skills });
    }

    const handleRemoveAdjustment = (index:number) => {
        const skills = [ ...current.adjustments]; 
        skills.splice(index, 1);
        setCurrent({ ...current, adjustments: skills })
    }


    return <Dialog fullWidth={true} open={show} onClose={handleCancel}>
    <DialogTitle>Trait: {current.name}</DialogTitle>
    <DialogContent>
        <FormGroup>
            <TextField type={"text"} variant="standard" value={current.name} onChange={(event) => onChangeName(event.target.value)} label="Name" />
            <TextField type={"text"} variant="standard" value={current.description} onChange={(event) => onChangeDescription(event.target.value)} label="Description" />
            
            <ModalContentSection title="Adjustments">
                <Typography variant="caption">Applies adjustments to the target value (skills/attributes).</Typography>
                {current.adjustments.map((skill, index) => {
                    return <Grid key={index} container boxSizing="border-box">
                        <Grid item xs>
                            <StatLookupField onChange={(value) => handleAdjustmentTarget(index, value)} value={skill.targetId} stats={[
                                ...character.primarySkills,
                                ...character.combatSkills,
                                ...character.spaceSkills,
                                ...character.attributes,
                                ...character.attributes.map(a => ({ id: `${a.id}_max`, name: `${a.name} Max` })),
                                ...AllStats
                            ]} />
                        </Grid>
                        <Grid item>
                            <NumberField step={1} value={skill.adjustment} onChange={(value) => handleChangeAdjustmentValue(index, value)} />
                        </Grid>
                        <Grid item>
                            <IconButton onClick={() => handleRemoveAdjustment(index)} ><EditIcon /></IconButton>
                        </Grid>
                    </Grid>
                })}
                <Button onClick={handleAddAdjustment}>Add Skill Adjustment</Button>
            </ModalContentSection>
            <AbilityEditor abilities={current.strongHits} setAbilities={(value) => onChangeStrongHits(value)} />
        </FormGroup>
    </DialogContent>
    <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleSubmit}>Save</Button>
    </DialogActions>
</Dialog>
}

const TraitDisplay = (props: TraitDisplayProps) => {
    const { title, character, traits, onChange } = props;

    const [ showModal, setShowModal ] = useState(false);
    const [ editTrait, setEditTrait ] = useState<Trait>();


    const handleAdd = () => {
        setShowModal(true);
        const trait = createTrait();
        setEditTrait(trait);
        onChange([ ...traits, trait]);
    }

    const handleEditTrait = (index: number) => {
        setEditTrait(traits[index]);
        setShowModal(true);
    }

    const handleRemoveTrait = ( index: number) => {
        const allTraits = [ ...traits ];
        allTraits.splice(index, 1);
        onChange(allTraits);
    }

    const handleSaveTrait = (trait: Trait) => {
        onChange(traits.map((a) => {
                return a.id === trait.id ? trait : a;
            }));
            setShowModal(false);
    }

    return <Section title={title} collapsable actions={[
        { action: handleAdd, label: <AddIcon /> }
        ]}>
        { editTrait && <EditModal show={showModal} onClose={() => setShowModal(false)} character={character}  onChange={handleSaveTrait} value={editTrait} /> }
        {traits.map((trait, index) => <div key={index}>
            <div>{trait.name}<EditIcon sx={{verticalAlign: "bottom"}} onClick={() => handleEditTrait(index)}/> <CloseIcon sx={{verticalAlign: "bottom"}} onClick={() => handleRemoveTrait(index)} /></div>
                <Typography variant="body2">{trait.description}</Typography>
                <Divider />
            </div>)}
    </Section>
}

export default TraitDisplay;
import { ThemeOptions, createTheme, getContrastRatio, darken, lighten } from '@mui/material/styles';

declare module '@mui/material/styles' {
  interface Palette {
    headingDark: Palette['primary'];
    heading: Palette['primary'];
  }

  interface PaletteOptions {
    headingDark?: PaletteOptions['primary'];
    heading?: Palette['primary'];
  }

  interface Theme {
    fontSize: {
      normal: string;
      heading: string;
    };
  }

  interface ThemeOptions {
    fontSize: {
      normal: string;
      heading: string;
    }
  }
}

const buildTheme = (primary: string, secondary: string): ThemeOptions => {
  const primaryText = getContrastRatio(primary, "#000000") > 0.5 ? "#fff" : "#000";
  return createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: primary,
        light: lighten(primary, 0.75),
        dark: darken(primary, 0.5),
        contrastText: primaryText,
      },
      secondary: {
        main: secondary,
      },
      headingDark: {
        main: '#000000',
        light: '#000000',
        dark: '#ffffff',
        contrastText: '#ffffff',
      },
      heading: {
        main: "#3c61b9",
        light: "#3c61b9",
        dark: "#000000",
        contrastText: "#ffffff"
      },
      text: {
        primary: "#2F2F2F",
      }
    },
    typography: {
      fontFamily: [
        '"Segoe UI"',
        '-apple-system',
        'BlinkMacSystemFont',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
    },
    fontSize: {
      heading: "1.4rem",
      normal: "1rem"
    }
  });
}

let currentTheme = buildTheme("#194a1c","#285b72");
type ThemeCallback = (theme: ThemeOptions) => void;
const themeCallbacks: ThemeCallback[] = [];

export const AddThemeCallback = (callback: ThemeCallback) => themeCallbacks.push(callback);
export const RemoveThemeCallback = (callback: ThemeCallback) => {
  const index = themeCallbacks.indexOf(callback);
  themeCallbacks.splice(index, 1);
}

export const CurrentTheme = () => { return currentTheme; };
export const ChangeTheme = (primary: string, secondary: string) => {
  currentTheme = buildTheme(primary, secondary);
  themeCallbacks.forEach((callback) => callback(currentTheme));
}
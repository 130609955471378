import { useEffect, useState } from 'react';
import { PlayerCharacter, Stat } from "../model/character";
import { Autocomplete, TextField, TextFieldVariants } from '@mui/material';

export interface StatLookupFieldProps {
    stats: Stat[];
    value: string;
    variant?: TextFieldVariants;
    onChange: (value: string) => void;
    label?: string
}

const StatLookupField = (props: StatLookupFieldProps) => {
    const { stats, value, variant, label, onChange } = props;

    const handleChange = (item: Stat | null) => {
        onChange(item?.id ?? "");
    }

    if(stats.length === 0) {
        return <Autocomplete
            disabled
            options={[]}
            renderInput={(params) => <TextField {...params} label="Lookup" />}
         />
    } else {
        return <Autocomplete
            value={stats.find(f => f.id === value)}
            onChange={(event, value) => handleChange(value)}
            options={stats}
            getOptionLabel={(option) => option.name}
            autoComplete
            disableClearable
            fullWidth
            renderInput={(params) => <TextField {...params} variant={variant ?? "standard"} label={label} />}
         />
    }
}

export default StatLookupField;
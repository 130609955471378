import { Button, ButtonProps } from "@mui/material"


export const ButtonFlatIcon = (props: ButtonProps) => {
    return <Button style={{ borderRadius: 0, boxShadow: "none", padding: 0, minWidth: "unset", aspectRatio: "1/1"}} {...props} variant="contained">{props.children}</Button>
}

const ButtonFlat = (props: ButtonProps) => {
    return <Button style={{ borderRadius: 0, boxShadow: "none", padding: "6px 14px", minWidth: "unset"}} {...props} variant="contained">{props.children}</Button>
}

export default ButtonFlat
import { Autocomplete, Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormGroup, Grid, Stack, TextField, Typography } from "@mui/material";
import { Ability } from "../../model/character";
import { v4 } from 'uuid';

import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import { ReactNode, useContext, useEffect, useState } from "react";
import { CharacterContext } from "../../model/characterContext";
import { ModalContentSection } from "../../components/Modal";

const defaultAbility: Ability = {
    id: "",
    name: "New Strong Hit",
    limitedUseMax: 1,
    limits: "",
    requirements: [],
    results: "",
    type: "",
}

const FormFieldLabel = (props: { children?: ReactNode, label: string}) => {
    return <Stack alignItems="center" direction="row" spacing={2}>
        <Typography sx={{ width: { sm: "25%", md: "40%"}}}>{props.label}</Typography>
        <Box flexGrow={1}>{props.children}</Box>
    </Stack>
}

interface AbilityDialogProps {
    ability: Ability;
    open: boolean;
    save: (value: Ability) => void;
    close: () => void;
}

const AbilityDialog = (props: AbilityDialogProps) => {
    const { ability, open, save, close} = props;

    const [ current, setCurrent ] = useState<Ability>(ability);

    useEffect(() => {
        setCurrent(ability);
    }, [ability]);

    const onChange = (key: string, value: any) => {
        setCurrent((oldValue) => { return { ...oldValue, [key]: value}});
    }

    return <Dialog fullScreen open={open} onClose={close}>
        <DialogTitle>Edit Ability</DialogTitle>
        <DialogContent>
            <Container >
                <FormGroup>
                    <FormFieldLabel label="Name">
                        <TextField fullWidth type="text" variant="standard" value={current.name} onChange={(event) => onChange("name", event.currentTarget.value)} />
                    </FormFieldLabel>
                    <FormFieldLabel label="Requirements">
                        <TextField fullWidth type="text" variant="standard" value={current.requirements} onChange={(event) => onChange("requirements", event.currentTarget.value)} />
                    </FormFieldLabel>
                    <FormFieldLabel label="Limits">
                        <TextField fullWidth type="text" variant="standard" value={current.limits} onChange={(event) => onChange("limits", event.currentTarget.value)} />
                    </FormFieldLabel>
                    <FormFieldLabel label="Results">
                        <TextField fullWidth type="text" variant="standard" value={current.results} onChange={(event) => onChange("results", event.currentTarget.value)} />
                    </FormFieldLabel>
                    <FormFieldLabel label="Type">
                        <Autocomplete
                            freeSolo
                            fullWidth
                            id="ability"
                            options={[
                                "Strong Hit",
                                "Triggered",
                                "Conditional"
                            ]}
                            renderInput={(params) => <TextField {...params} type="text" variant="standard"  />}
                            value={current.type}
                            onChange={(event, newValue) => onChange("type", newValue)}
                            />
                    </FormFieldLabel>
                </FormGroup>
            </Container>
        </DialogContent>
        <DialogActions>
            <Button onClick={close}>Cancel</Button>
            <Button onClick={() => { close(); save(current)}}>Save</Button>
        </DialogActions>
    </Dialog>
}

interface StrongHitEditorProps {
    abilities: Ability[];
    setAbilities: (value: Ability[]) => void;
}

const AbilityEditor = (props: StrongHitEditorProps) => {
    const characterContext = useContext(CharacterContext)!;
    const { abilities, setAbilities } = props;
    const [ edit, setEdit ] = useState<Ability>(defaultAbility);
    const [ editIndex, setEditIndex] = useState<number>();

    const onChange = (index: number, value: Ability) => {
        const hits = [ ...abilities ];
        hits[index] = value;
        setAbilities(hits)
        characterContext.setLimitedUse(value.id, value.limitedUseMax);
    }

    const handleAdd = () => {
        const newHit = { ... defaultAbility, id: v4() };
        const hits = [ ...abilities, newHit ];
        setAbilities(hits);
        characterContext.setLimitedUse(newHit.id, newHit.limitedUseMax);
    }

    const handleRemove = (index: number) => {
        const hits = [ ...abilities ];
        const hit = hits.splice(index, 1);
        setAbilities(hits);
        characterContext.removeLimitedUse(hit[0].id);
    }

    return <>
        <AbilityDialog
            close={() => setEditIndex(undefined)}
            open={editIndex !== undefined}
            ability={edit}
            save={(value) => onChange(editIndex!, value)}
            />
        <ModalContentSection title="Abilities" onAdd={handleAdd}>
            {abilities.map((sh,index) => {
                return <Stack key={index} alignItems="center" direction="row">
                    <Box flexGrow={1}>{sh.name}</Box>
                    <EditIcon onClick={() => { setEditIndex(index); setEdit(sh); }} />
                    <CancelIcon onClick={() => handleRemove(index)} />
                </Stack>
            })}

        </ModalContentSection>
    </>
}

export default AbilityEditor;
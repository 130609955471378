import { Box, Collapse, Stack, useTheme } from "@mui/material"
import SectionHeader, { SectionHeaderAction } from "./SectionHeader"
import { ReactNode, useState } from "react";

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

interface SectionProps {
    children?: ReactNode;
    actions?: SectionHeaderAction[];
    collapsable?: boolean;
    title: string;
}

const Section = (props: SectionProps) => {
    const [ collapse, setCollapse ] = useState(false);
    const theme = useTheme();

    let actions = props.actions;

    if(props.collapsable === true){
        if(actions === undefined) {
            actions = [];
        }
        actions = [...actions, 
            {
                action: () => setCollapse(!collapse),
                label: collapse ?
                <ExpandLessIcon /> : 
                <ExpandMoreIcon />
            }
        ]
    }
    
    return <Box marginTop={2}>
        
        <SectionHeader actions={actions}><Stack direction="row" alignItems="center" spacing={3}>{props.title}</Stack></SectionHeader>
        <Collapse in={!collapse}>
            {props.children}
        </Collapse>
    </Box>
}

export default Section;
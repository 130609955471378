import { Box, ClickAwayListener, Grid, Typography, useTheme } from "@mui/material";
import { AttributeDisplay as Attribute } from "../model/character";

import styled from 'styled-components';

import { ReactComponent as LeftBunt } from '../images/Heading_EvenBuntLeft.svg';
import { ReactComponent as RightBunt }  from '../images/Heading_EvenBuntRight.svg';
import { useState } from "react";

const NumberFade = styled.div<{ selected: boolean, xPosition: string }>`
    position: absolute;
    opacity: ${(props) => props.selected ? "1" : "0"};
    left: ${(props) => props.selected ? props.xPosition : "0px"};
    transition: all 0.2s;
`;

export interface AttributeDisplayProps {
    value: Attribute;
    onClick?: () => void;
}

const AttributeDisplay = (props: AttributeDisplayProps) => {
    const {value, onClick} = props;
    const [ selected, setSelected ] = useState(false);
    const theme = useTheme();
    const color = value.current === value.withDamage ? theme.palette.text.primary : theme.palette.error.main;
    return <Grid item xs={4} paddingY={1} onClick={onClick}>
        <ClickAwayListener onClickAway={() => setSelected(false)}>
            <Box onClick={() => setSelected(true)} textAlign={"center"} sx={{ cursor: "pointer"}}>
                <Box display={"inline-block"} position="relative">
                    <NumberFade xPosition="25px" selected={selected}><Typography fontWeight={700} fontSize={18} color={color}>{value.damage}</Typography></NumberFade>
                    <NumberFade xPosition="-25px"selected={selected}><Typography fontWeight={700} fontSize={18}>{value.current}</Typography></NumberFade>
                    <Typography fontWeight={700} fontSize={25} display={"inline-block"} color={color} >{value.withDamage}</Typography>
                </Box>
            </Box>
        </ClickAwayListener>
        <Box display="flex" height={"20px"} maxWidth={"100px"} margin={"auto"}>
            <LeftBunt fill={theme.palette.primary.main} />
            <Box flexGrow={1} sx={{backgroundColor: theme.palette.primary.main}}><Typography fontSize={15} textAlign={"center"} color={theme.palette.headingDark.contrastText}>{value.name}</Typography></Box>
            <RightBunt fill={theme.palette.primary.main} />
        </Box>
    </Grid>;
}

export default AttributeDisplay;
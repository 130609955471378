import { ClickAwayListener, Collapse, Grid, Tooltip, Typography, useTheme } from "@mui/material";
import { PlayerCharacterDisplay, WeaponDisplay as WD, Weapon, PlayerCharacter } from "../../model/character";
import SimpleTable from "../../components/SimpleTable";
import { ReactNode, useState } from "react";
import HeadingBar from "../../components/HeadingBar";
import { StatDisplay } from "../../components/StatDisplay";
import CollapseClickAway from "../../components/CollapseClickAway";
import WeaponDetailDisplay from "../../components/displays/WeaponDetailDisplay";

import { ReactComponent as RangeIcon } from "../../components/icons/raw/Icon_Range.svg";
import { ReactComponent as EnduranceIcon } from "../../components/icons/raw/Icon_Endurance.svg";
import { ReactComponent as CritDamageIcon } from "../../components/icons/raw/Icon_Crit_Damage.svg";
import { ReactComponent as DiceIcon } from "../../components/icons/raw/Icon_Dice.svg";
import { ReactComponent as HitIcon } from "../../components/icons/raw/Icon_Hit.svg";

interface WeaponDisplayProps {
    character: PlayerCharacter;
    characterDisplay: PlayerCharacterDisplay;
    setSelected: (value: number) => void;
}

const Header = (props: { children?: ReactNode, tooltip?: string}) => {
    const [ open, setOpen ] = useState(false);

    return <ClickAwayListener onClickAway={() => setOpen(false)}>
        <Tooltip title={props.tooltip} open={open} onClick={() => setOpen(true)} >
            <div>{props.children}</div>
        </Tooltip>
    </ClickAwayListener>
}

const WeaponRow = (props: {index: number, weapon: Weapon, display: WD}) => {
    const {index, display, weapon} = props;
    const [ show, setShow ] = useState(false);

    const details = <WeaponDetailDisplay weaponDisplay={display} weapon={weapon} />;

    return <CollapseClickAway collapseContent={details}>
        <Grid container>
            <Grid item xs={7}><Typography fontWeight={700} fontSize={15}>{display.name}</Typography></Grid>
            <Grid item xs={1}>{display.hitDice}<Typography fontSize={"0.75em"} display={"inline"}>d6</Typography></Grid>
            <Grid item xs={1}>{StatDisplay(display.hitBonus + display.skillBonus)}</Grid>
            <Grid item xs={1}>{display.range}</Grid>
            <Grid item xs={1}>{StatDisplay(display.enduranceDamage)}</Grid>
            <Grid item xs={1}>{display.critDamage}</Grid>
            <Grid item xs={12}><Typography variant="caption">{display.features.join(", ")}</Typography></Grid>
            <Grid item xs={12}><Typography variant="caption"><Collapse in={show}>{display.description}</Collapse></Typography></Grid>
        </Grid>
    </CollapseClickAway>
}

const WeaponDisplay = (props: WeaponDisplayProps) => {
    const { characterDisplay, character, setSelected } = props;
    const theme = useTheme();

    return <>
        <HeadingBar extendBunts>
            <Grid container paddingX={1} alignItems="center" height="100%">
                <Grid item xs={7} lineHeight="0em"><Header>Name</Header></Grid>
                <Grid item xs={1} lineHeight="0em"><Header tooltip="Dice"><DiceIcon fill={theme.palette.primary.contrastText} height="1em" /></Header></Grid>
                <Grid item xs={1} lineHeight="0em"><Header tooltip="Hit Dice"><HitIcon fill={theme.palette.primary.contrastText} height="1em" /></Header></Grid>
                <Grid item xs={1} lineHeight="0em"><Header tooltip="Range"><RangeIcon fill={theme.palette.primary.contrastText} height="1em" /></Header></Grid>
                <Grid item xs={1} lineHeight="0em"><Header tooltip="Endurance Damage"><EnduranceIcon fill={theme.palette.primary.contrastText} height="1em" /></Header></Grid>
                <Grid item xs={1} lineHeight="0em"><Header tooltip="Crit Damage"><CritDamageIcon fill={theme.palette.primary.contrastText} height="1em" /></Header></Grid>
            </Grid>
        </HeadingBar>
        <SimpleTable entries={characterDisplay.weapons.filter(w => w.isHeld).map((w, index) => {
            const weapon = character.weapons.find(we => we.id === w.id);
            return <WeaponRow index={index} display={w} weapon={weapon!}/>
        })} />
    </>
}

export default WeaponDisplay
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";

export interface ConfirmDialogProps {
    message: string;
    show: boolean;
    yesLabel?: string;
    noLabel?: string;
    onYes: () => void;
    onNo: () => void;
}

const ConfirmDialog = (props:ConfirmDialogProps) => {
    const { message, show, yesLabel, noLabel, onYes, onNo } = props;

    return <Dialog
    open={show}
    onClose={onNo}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description">
    <DialogContent>
      <DialogContentText>
        {message}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onYes} variant="contained">{yesLabel ?? "Yes"}</Button>
      <Button onClick={onNo} autoFocus>{noLabel ?? "No"}</Button>
    </DialogActions>
  </Dialog>
}

export default ConfirmDialog;
import { useContext } from "react"
import { PlayerCharacterDisplay } from "../../model/character"
import { Box, Grid, Typography, useTheme } from "@mui/material"

import { CharacterContext } from "../../model/characterContext";
import { AcquisitionRolls, ArmorAtZeroStat, ArmorStat, AugmentedNumber, CombatOrder, Defence, EnduranceCurrentStat, EnduranceMaxStat, GritReroll, Movement, Recovery } from "../../model/statsConstant";
import HeadingBar from "../../components/HeadingBar";

export interface SubStatsDisplayProps {
    character: PlayerCharacterDisplay
}

const SubStatsDisplay = (props: SubStatsDisplayProps) => {
    const theme = useTheme();
    const characterContext = useContext(CharacterContext)!;

    const equipment = props.character.equipmentSlotsCurrent;
    const equipmentMax = props.character.equipmentSlots;



    const gritReroll = (props.character.attributes.find(a => a.id == "grit")?.current ?? 0) + characterContext.getAdjustment(GritReroll.id)
    const gritCurrent = gritReroll;
    const defence = 10 + (props.character.attributes.find(a => a.id == "ref")?.current ?? 0) + characterContext.getAdjustment(Defence.id); // Change to current
    const enduranceMax = 10 + (props.character.attributes.find(a => a.id == "str")?.current ?? 0) * 5 + characterContext.getAdjustment(EnduranceMaxStat.id);
    const endurance = Math.min(10 + (props.character.attributes.find(a => a.id == "str")?.current ?? 0) * 5 + characterContext.getAdjustment(EnduranceCurrentStat.id), enduranceMax);
    let armour = (props.character.outfits.find(outfit => outfit.isWorn)?.armor ?? 0) + characterContext.getAdjustment(ArmorStat.id);
    let armourAtZero = (props.character.outfits.find(outfit => outfit.isWorn)?.armorAtZero ?? 0) + characterContext.getAdjustment(ArmorAtZeroStat.id);
    const munition = 3
    const recovery = (props.character.attributes.find(a => a.id == "grit")?.current ?? 0) + characterContext.getAdjustment(Recovery.id); // Change to current
    const combatOrder = (props.character.attributes.find(a => a.id == "int")?.current ?? 0) + characterContext.getAdjustment(CombatOrder.id); // Change to current
    const augmented = characterContext.getAdjustment(AugmentedNumber.id);
    const movement = characterContext.getAdjustment(Movement.id);
    const acquisitionRolls = characterContext.getAdjustment(AcquisitionRolls.id);
    
    const stats = [
        { label: "Equipment Slots", value: `${equipment} / ${equipmentMax}`, },
        { label: "Grit Re-Roll", value: `${gritCurrent} / ${gritReroll}` },
        { label: "Recovery", value: recovery },
        { label: "Combat Order", value: combatOrder },
        { label: "Defence", value: defence },
        { label: "Endurance", value: `${endurance} / ${enduranceMax}` },
        { label: "Armour", value: `${armour} | ${armourAtZero}` },
        // { label: "Munition", value: munition },
        // { label: AugmentedNumber.name, value: augmented },
        // { label: Movement.name, value: movement },
        { label: AcquisitionRolls.name, value: acquisitionRolls },
    ]

    return <Grid marginTop={1} container spacing={1}>
        {stats.map((s, index) => {
            return <Grid key={index} item xs={4} sm={3} paddingY={1}>
                <Typography fontWeight={700} fontSize={25} textAlign={"center"}>{s.value}</Typography>
                <HeadingBar center>{s.label}</HeadingBar>
            </Grid>;
        })}
    </Grid>
}

export default SubStatsDisplay;
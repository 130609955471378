import { Box, Button, Stack, Typography, styled } from "@mui/material";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useState } from "react";
import { CharacterFileType, saveCharacter } from "../master/data";
import { PlayerCharacter } from "../model/character";
import { useNavigate } from "react-router-dom";
import Page from "../components/Page";

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const ImportCharacterPage = () => {
    const navigate = useNavigate();
    
    const [file, setFile] = useState<PlayerCharacter>();
    const [fileError, setFileError] = useState<string>();
    const reader = new FileReader();
    reader.onload = function(event) {
        if(event.target === null){
            setFile(undefined)
            setFileError("File upload error");
        }
        else {
            if(typeof(event.target.result) === 'string'){
                const data = event.target.result;
                const loadedCharacter: PlayerCharacter = JSON.parse(data);
                setFile(loadedCharacter);
            }else{
                setFileError("Wrong file type");
            }
        }
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if(event.target.files !== null) {
            try {
                const file = event.target.files[0];
                reader.readAsText(file);
            } catch (e: any) {
                setFile(undefined);
                setFileError("File upload error");
            }
        }
    }

    const handleSave = () => {
        saveCharacter(file!);
        navigate("/");
    }

    return <Page>
        <Box paddingY={3} textAlign="center">
            <Stack direction="column" spacing={2}>
                <Typography variant="h3" textAlign="center">Import Character</Typography>
                {file !== undefined ? <>
                    <Typography variant="h4">{file.name}</Typography>
                    {file.backstory !== "" && <div>{file.backstory}</div>}
                    <div>Competence: {file.competence}</div>
                    <Stack direction="row" spacing={2} justifyContent="center">
                        <Button variant="contained" onClick={() => handleSave()}>Save</Button>
                        <Button variant="contained" onClick={() => setFile(undefined)}>Clear</Button>
                    </Stack>
                    </> : <>
                        <Typography>Upload an exported {CharacterFileType} file.</Typography>
                        {fileError !== undefined && <Typography>{fileError}</Typography>}
                        <Stack direction="row" spacing={2} justifyContent="center">
                        <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                            Upload file
                            <VisuallyHiddenInput type="file" accept={`${CharacterFileType}`} onChange={handleFileChange} />
                        </Button>
                    </Stack>
                </>}
                    
            </Stack>
        </Box>
    </Page>;
        
}

export default ImportCharacterPage
import { Box, Container, useMediaQuery, useTheme } from "@mui/material";
import { ReactNode } from "react"

interface PageProps {
    children?: ReactNode
}

const Page = (props: PageProps) => {
    const theme = useTheme();
    const {children} = props;

    const backgroundColor = useMediaQuery(theme.breakpoints.up("sm")) ? "#ffffffe6" : "#fff";

    return <Container maxWidth={"sm"} fixed style={{ backgroundColor: backgroundColor, marginTop: "8px", padding: "0"}}>
        <Box padding={2} fontSize={theme.fontSize.normal}>
            {children}
        </Box>
    </Container>
}

export default Page;
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormGroup, Grid, IconButton, Stack, TextField, Typography } from "@mui/material";
import { Damage, PlayerCharacter, Stat, Trait } from "../../model/character"
import { useEffect, useState } from "react";
import StatLookupField from "../../components/StatLookupField";

import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { NumberField } from "../../components/formFields/NumberField";
import { EnduranceCurrentStat, EnduranceMaxStat } from "../../model/statsConstant";
import Section from "../../components/Section";

export interface TraitDisplayProps {
    character: PlayerCharacter;
    setCharacter: (character: PlayerCharacter) => void;
}

const EditModal = (props: { 
        value: Damage,
        show: boolean,
        character: PlayerCharacter,
        onChange: (value: Damage) => void,
        onClose: () => void,
    }) => {
    const { value, show, character, onClose, onChange } = props;

    const [ current, setCurrent ] = useState<Damage>(value);

    useEffect(() => {
        setCurrent(value);
    }, [value])
    

    const handleCancel = () => {
        onClose();
    }

    const handleSubmit = () => {
        onChange(current);
    }

    const handleAdjustmentTarget = (id: string) => {
        setCurrent({ ...current, targetId: id });
    }

    const handleChangeAdjustmentValue = (adjustment: number) => {
        setCurrent({ ...current, adjustment });
    }


    return <Dialog fullWidth={true} open={show} onClose={handleCancel}>
    <DialogTitle>Adjust Damage</DialogTitle>
    <DialogContent>
        <FormGroup>
            <Grid container boxSizing="border-box">
                <Grid item xs>
                    <StatLookupField onChange={(value) => handleAdjustmentTarget(value)} value={current.targetId} stats={[
                        ...character.primarySkills,
                        ...character.combatSkills,
                        ...character.spaceSkills,
                        ...character.attributes,
                    ]} />
                </Grid>
                <Grid item>
                    <NumberField step={1} value={current.adjustment} onChange={(value) => handleChangeAdjustmentValue(value)} />
                </Grid>
            </Grid>
        </FormGroup>
    </DialogContent>
    <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleSubmit}>Save</Button>
    </DialogActions>
</Dialog>
}

const DamageDisplay = (props: TraitDisplayProps) => {
    const { character, setCharacter } = props;

    const [ showModal, setShowModal ] = useState(false);
    const [ editIndex, setEditIndex ] = useState<number>();


    const handleAdd = () => {
        setShowModal(true);
        const damage: Damage = {
            adjustment: 1,
            targetId: "",
        }
        setEditIndex(character.damages.length);
        setCharacter({ ...character, damages: [ ...character.damages, damage ]});
    }

    const handleEditTrait = (index: number) => {
        setEditIndex(index);
        setShowModal(true);
    }

    const handleRemoveTrait = ( index: number) => {
        const damages = [ ...character.damages ];
        damages.splice(index, 1);
        setCharacter({ ...character, damages });
    }

    const handleSaveTrait = (damage: Damage, index: number) => {
        const damages = [ ...character.damages ];
        damages[index] = damage;
        setEditIndex(undefined);
        setCharacter({ ...character, damages });
        setShowModal(false);
    }

    const handleCancel = () => {
        setShowModal(false)
        setEditIndex(undefined);
    }

    const stats: Stat[] = [
        ...character.primarySkills,
        ...character.combatSkills,
        ...character.spaceSkills,
        ...character.attributes,
        EnduranceCurrentStat,
        EnduranceMaxStat,
    ];



    return <Section title="Damage" actions={[
        { action: handleAdd, label: <AddIcon /> }
    ]}>
        { editIndex !== undefined && <EditModal show={showModal} onClose={handleCancel} character={character}  onChange={(value) => handleSaveTrait(value, editIndex)} value={character.damages[editIndex]} /> }
        {character.damages.map((damage, index) => <div key={index}>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
                {stats.find(a => a.id === damage.targetId)?.name ?? "No damage target selected."}
                <Box flexGrow={1}>{damage.adjustment}</Box>
                <EditIcon sx={{verticalAlign: "bottom"}} onClick={() => handleEditTrait(index)}/> <CloseIcon sx={{verticalAlign: "bottom"}} onClick={() => handleRemoveTrait(index)} />
            </Stack>
            </div>)}
    </Section>
}

export default DamageDisplay;
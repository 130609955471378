import { ReactComponent as Icon } from './raw/Trade_Good_two-coins.svg'
import { SvgIcon, SvgIconProps } from "@mui/material";

const TradeGoodIcon = (props: SvgIconProps) => {
    return (
      <SvgIcon {...props}>
        <Icon />
      </SvgIcon>
    );
  }

export default TradeGoodIcon;
import { ReactComponent as Icon } from './raw/Utility_cannister.svg'
import { SvgIcon, SvgIconProps } from "@mui/material";

const UtilityIcon = (props: SvgIconProps) => {
    return (
      <SvgIcon {...props}>
        <Icon />
      </SvgIcon>
    );
  }

export default UtilityIcon;
import corpImage from '../images/species/Avatar_Corp.jpg'
import kaltoranImage from '../images/species/Avatar_Kaltoran.jpg'
import legionImage from '../images/species/Avatar_Legion.jpg'
import nephilimImage from '../images/species/Avatar_Nephilim.jpg'
import palantorImage from '../images/species/Avatar_Palantor.jpg'
import remnantImage from '../images/species/Avatar_Remnant.jpg'
import twifarImage from '../images/species/Avatar_Twi_Far.jpg'
import zhouImage from '../images/species/Avatar_Zhou.jpg'

export interface SpeciesData {
    name: string,
    image: string,
    color: string,
    description: string,
    subcultures: string[];
}

type SpeciesDataList = { [k: string]: SpeciesData}

export const SpeciesList: SpeciesDataList = {
    corp: {
        name: "Corp",
        image: corpImage,
        color: "#fbab19",
        description: "Hyper-libertarian capitalists who have rejected their racial identity.",
        subcultures: ["Neon Hunter", "Socialite", "Suit", "Vargartian", "(Other)"]
    },
    kaltoran: {
        name: "Kaltoran",
        image: kaltoranImage,
        color: "#3c61b9",
        description: "Haunted by their ancestral memories they turn to tight family bonds and new experiences for healing.",
        subcultures: ["Dark Tribe", "Pioneer", "Rascal", "Vagabond", "Watcher", "(Other)"],
    },
    legion: {
        name: "Legion",
        image: legionImage,
        color: "#c7151e",
        description: "Hulking super-soldiers struggling to flourish in a post-war universe.",
        subcultures: ["Auxilia - Foreign","Auxilia - Interior", "Auxilia - Operations", "Auxilia - Prime", "Exilia", "Nomad", "(Other)"],
    },
    nephilim: {
        name: "Nephilim",
        image: nephilimImage,
        color: "#1cb44c",
        description: "Genetically engineered monsters who won the Great War, now they must be more than they were made to be.",
        subcultures: ["Emissary", "Gargantuan", "Lessercrat", "Risencrat", "Sentient Munition", "Tenebrous", "(Other)", ]
    },
    palantor: {
        name: "Palantor",
        image: palantorImage,
        color: "#b32589",
        description: "Digitized human minds, refugees back into a physical reality they have not experienced for thousands of years.",
        subcultures: ["MVP - Bear", "MVP - Butterfly", "MVP - Crane", "MVP - Fox", "MVP - Lion", "MVP - Monkey", "MVP - Rat", "MVP - Snake", "MVP - Squirrel", "MVP - Toad", "Puppeteer", "Spark - Blue", "Spark - Green", "Spark - Pink", "Spark - Purple", "Spark - Yellow", "(Other)", ],
    },
    remnant: {
        name: "Remnant",
        image: remnantImage,
        color: "#1a162d",
        description: "War criminals ressurected from death by a deity to live new and holy lives.",
        subcultures: ["All-Faith", "All-Truth", "Psion Mind", "(Other)"],
    },
    twifar: {
        name: "Twi-Far",
        image: twifarImage,
        color: "#f37521",
        description: "Nomadic space hippies on the run who are two entities in the one body.",
        subcultures: ["Dragon Symphony", "Efreeti Symphony", "Lampad Symphony", "Phoenix Symphony", "Salamander Symphony", "Seraphim Symphony", "(Other)"],
    },
    zhou: {
        name: "Zhou",
        image: zhouImage,
        color: "#6e3a22",
        description: "World destroying goo that has gained sentience and made bodies for themselves.",
        subcultures: ["Chrome", "Organic", "Wilder", "(Other)"],
    },
}
import { Grid } from "@mui/material";
import { Outfit, OutfitDisplay } from "../../model/character"

export interface OutfitDetailDisplayProps {
    outfit: Outfit;
    outfitDisplay: OutfitDisplay
}

const OutfitDetailDisplay = (props: OutfitDetailDisplayProps) => {
    const { outfit, outfitDisplay } = props;
    return <Grid container marginTop={2}>
        <Grid item xs={12}>{outfit.description}</Grid>
        <Grid item xs={8} md={4}>Armor:</Grid>
        <Grid item xs={4} md={2}>{outfitDisplay.armor} ({outfitDisplay.armorAtZero})</Grid>
        <Grid item xs={8} md={4}>Defence:</Grid>
        <Grid item xs={4} md={2}>{outfitDisplay.defence}</Grid>
        <Grid item xs={4}>Endurance:</Grid>
        <Grid item xs={8}>{outfitDisplay.endurance}</Grid>
        <Grid item xs={4}>Outfit types:</Grid>
        <Grid item xs={8}>{outfitDisplay.outfitType.length === 0 ? "None" : outfitDisplay.outfitType.join(", ")}</Grid>
        <Grid item xs={8} md={4}>Modifications:</Grid>
        <Grid item xs={4} md={2}>{outfit?.modifications.length ?? 0}</Grid>
        <Grid item xs={8} md={4}>Variations:</Grid>
        <Grid item xs={4} md={2}>{outfit?.variations.length ?? 0}</Grid>
    </Grid>
}

export default OutfitDetailDisplay;
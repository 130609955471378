import { createContext } from "react";
import { PlayerCharacter, PlayerCharacterDisplay } from "./character";

interface CharacterContextModel {
    setLimitedUse: (key: string, max: number) => void;
    removeLimitedUse: (key: string) => void;
    character: PlayerCharacter;
    characterDisplay: PlayerCharacterDisplay;
    getAdjustment: (id: string) => number;
}

export const CharacterContext = createContext<CharacterContextModel | undefined>(undefined);